@import "_variables.scss";

.e-partner {
  position: relative;
  font-family: $gothamPro-reg-font;
  .content-wrapper, .wrapper {
    background-color: #eff3f3;
  }

  [class^="ti-"], [class*=" ti-"], [class*="fa-ti-"] {
    font-family: $themify;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  //sidebar-menu:
  .main-sidebar {
    width: 255px;
    background-color: #fff;
    box-shadow: -8px 18px 12px 2px rgba(0, 0, 0, 0.65);
    bottom: 0;
  }
  .sidebar {
    padding-bottom: 0;
  }
  .sidebar a {
    color: #555555;
    font-size: 14px;
    transition: all .1s;
    font-family: $gotham-light-font;
  }

  &.sidebar-collapse {
    .sidebar-menu .fa-angle-left:after {
      color: #fff;
      position: relative;
      top: -5px;
      right: -15px;
    }
  }

  .sidebar-menu {
    .fa-angle-left {
      &:before {
        display: none;
      }
      &:after {
        content: '\e64b';
        font-family: $themify;
        color: #bec1c1;
      }
    }
    li.active .fa-angle-left:after {
      content: "\e648";
    }
    li.active > a > .pull-right-container > .fa-angle-left {
      transform: inherit;
    }

    & > li {
      border-left: none;
      border-top: 1px solid #f3f6f6;
      &.active {
        & > a {
          color: #6fa7c8;
          background: none;
          position: relative;
          &.fa-angle-left {
            color: #eee;
          }

          &:before {
            content: '';
            display: block;
            height: 100%;
            top: 0;
            left: 0;
            width: 4px;
            position: absolute;
            background-image: linear-gradient(to top, #ca788a 0%, #bb7c91 11%, #4298cc 100%);
          }
        }
        i {
          color: #ca788a;
        }
      }
      &:hover {
        > a {
          color: #6fa7c8;
          background: none;
        }
      }
      & > a {
        border-left: none;
        padding: 16px 5px 16px 17px;
        position: relative;

        i {
          color: #54bece;
        }
      }

      & > .treeview-menu {
        box-shadow: inset 0 27px 17px -33px rgba(0, 0, 0, .3),
        inset 0px -7px 18px -10px #CCC;
        background-color: #fafafa;
        border-top: 1px solid #eee;
        padding: 15px 18px 18px 32px;
        margin: 0;
      }
    }

    & .treeview-menu {
      margin-left: 0;

      & > li {
        &.active a {
          color: #6fa7c8;
        }
        & > a {
          padding: 11px 5px 11px 15px;
          background: none;
          color: #8e8e8e;
          span {
            font-size: 14px;
          }
          &:hover {
            background: none;
            color: #6fa7c8;
          }
          &:active {
            background: none;
            color: #6fa7c8;
          }
        }
      }
    }
  }
  .content-wrapper {
    margin-left: 270px;
  }
  .main-header {

    .navbar {
      margin-left: 255px;
    }
  }

  .fa-ti-dirlink{
    background: url("../images/promo/link_mint.svg") no-repeat;
    vertical-align: middle;
    height: 10px;
  }

  .fa-ti-dirlink+span {
    vertical-align: middle;
  }

  li.active
  .fa-ti-dirlink {
    background: url("../images/promo/link_pink.svg") no-repeat;
  }

  .fa-ti-dashboard {
    &:before {
      content: "\e6aa";
      display: block;
      font-size: 18px;
      position: relative;
      top: 3px;
    }
  }
  .fa-ti-promo {
    &:before {
      content: "\e654";
      display: block;
      font-size: 18px;
      position: relative;
      top: 4px;
    }
  }
  .fa-ti-analytics {
    &:before {
      content: "\e6bb";
      display: block;
      font-size: 18px;
      position: relative;
      top: 1px;
    }
  }
  .fa-ti-websites {
    &:before {
      content: "\e6c9";
      display: block;
      font-size: 18px;
      position: relative;
      top: 1px;
    }
  }
  .fa-ti-payments {
    &:before {
      content: "\e6cd";
      display: block;
      font-size: 18px;
      position: relative;
      top: 1px;
    }
  }
  .fa-ti-news {
    &:before {
      content: "\e62e";
      display: block;
      font-size: 18px;
      position: relative;
      top: 1px;
    }
  }
  .ti-plan {
    width: 24px;
    min-width: 24px;
    &:before {
      content: '\e699';
      display: inline-block;
      font-size: 18px;
      position: relative;
      top: 1px;
      width: 24px;
      min-width: 24px;
    }
  }
  .ti-balance {
    width: 24px;
    min-width: 24px;
    &:before {
      content: '\e67d';
      display: inline-block;
      font-size: 18px;
      position: relative;
      top: 1px;
      width: 24px;
      min-width: 24px;
    }
  }
  .sidebar-menu > li > a > .fa {
    width: 24px;
  }

  .e-outer-user-info {
    min-width: 214px;
    max-width: 214px;
    box-sizing: border-box;
    .e-user-more-info {
      line-height: 24px;
      display: inline-block;
    }
  }
  .e-current-plan {
    margin-bottom: 5px;
    white-space: normal;
  }
  .e-sidebar-menu {
    padding: 40px 0 28px;
    & > li {
      border: none;
      margin-bottom: 14px;
      &:hover {
        a {
          color: #555555;
        }
      }
      & > a {
        padding: 0 5px 0 17px;
        display: flex;
        cursor: default;
      }
      &.partner-info-btn {
        margin-top: 22px;
        margin-bottom: 0;
        padding: 0 10px 0 21px;
        display: flex;
        & > a {
          line-height: 1;
          font-size: 13px;
          color: #6fa7c8;
          outline: none;
          display: block;
          padding: 12px 27px;
          border-radius: 20px;
          border: 1px solid #6fa7c8;
          text-transform: uppercase;
          white-space: nowrap;
          transition: all .1s;
          font-family: $gothamPro-reg-font;
          cursor: pointer;
          &:hover {
            border-color: transparent;
            background: #6fa7c8;
            color: #fff;
          }
          &:focus {
            color: #fff;
            border-color: #6fa7c8;
            background-color: #6fa7c8;
            box-shadow: inset 2px 2px 6px rgba(0, 0, 0, .3);
          }
          &:active {
            color: #fff;
            border-color: #6fa7c8;
            background-color: #6fa7c8;
            box-shadow: inset 2px 2px 6px rgba(0, 0, 0, .3);
          }
        }
      }
    }
    span {
      color: #a4a9a9;
      padding-left: 2px;
      &.e-current-plan,
      &.balance {
        color: #555555;
      }
    }
  }
  .sidebar-menu li > a > .fa-angle-left, .sidebar-menu li > a > .pull-right-container > .fa-angle-left {
    margin-top: -1px;
  }
  .partner-id{
    padding: 0px 5px 0 8px !important;
  }
}

@media (min-width: 768px) {
  .e-partner {
    &.sidebar-mini.sidebar-collapse {
      .e-sidebar-menu > li.partner-info-btn {
        display: none;
      }
      .e-sidebar-menu {
        padding: 40px 0 2px;
        .e-outer-user-info {
          height: auto !important;
        }
      }

    }
    &.sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right) {
      width: 230px;
      height: 55px;
      padding-top: 19px;
      background-color: #00decd;
      left: 54px;
      border-radius: inherit;
      border: inherit;
      color: #fff;
    }

    &.sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
      width: 230px;
      left: 51px;
      top: 55px;
      border: none;
      border-radius: inherit;
      background: #00c0cd;
      box-shadow: none;
      li {
        &:hover {
          a {
            color: #ccc;
          }
        }
        a {
          color: #fff;
        }
      }
    }
    &.sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a span.pull-right {
      height: auto;
      background-color: #000;
    }
    &.sidebar-mini.sidebar-collapse {
      .fa-angle-left:before {
        position: relative;
      }
    }
  }
  .e-partner.sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    padding-left: 6px;
  }
  .e-partner.sidebar-mini.sidebar-collapse .sidebar-menu > li a span.pull-right-container {
    width: 10px !important;
    position: absolute;
    left: 225px !important;
    display: block;
    height: 10px !important;
  }
  .e-partner.sidebar-mini.sidebar-collapse .e-sidebar-menu {
    & > li {
      & > a {
        height: 54px;
        &:hover {
          .e-outer-user-info {
            top: -16px;
          }
        }
        span {
          color: #fff;
        }
        .e-user-more-info {
          display: none;
        }
      }
    }
  }
  .e-partner.sidebar-mini.sidebar-collapse .e-sidebar-menu > li {
    margin-bottom: 0;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar {
    z-index: 100000;
  }
}

@media only screen and (max-width: 767px) {
  .e-partner {
    .main-sidebar {
      transform: translate(-255px, 0);
      padding-top: 132px;
    }
    .content-wrapper {
      margin-left: 0;
      padding-top: 56px;
    }
    &.sidebar-open .content-wrapper, &.sidebar-open .right-side, &.sidebar-open .main-footer {
      transform: translate(255px, 0);
    }
    &.sidebar-open {
      .main-sidebar {
        transform: translate(0, 0);
      }
    }
    .e-sidebar-menu {
      padding: 37px 0 28px;
    }
  }
}
@media only screen and (max-width: 479px) {
  .e-partner {
    .main-sidebar {
      padding-top: 91px;
    }
    .content-wrapper {
      padding-top: 18px;
    }
    .e-sidebar-menu {
      padding: 31px 0 28px;
    }
  }
}


