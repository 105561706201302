@import "_variables.scss";

[class^="ti-"], [class*=" ti-"] {
  font-family: $themify;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.e-partner {
  .main-header {
    //z-index: 100001;
    z-index: 1100;
  }
  .main-header .languages-menu .lang-menu {
    display: block;
    font-size: 16px;
    line-height: 20px;
    position: relative;
  }

  .main-header .languages-menu .lang-menu .flag:before {
    width: 16px;
    height: 16px;
    background-size: 16px 16px;
    -webkit-background-size: 16px 16px;
  }

  .main-header .languages-menu .dropdown-menu,
  .main-header .languages-menu .btn {
    width: 100%;
  }

  .main-header .languages-menu .dropdown-toggle:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
  }
  .main-header {
    .navbar {
      background-color: #eff3f3;
      .nav {
        & > li {
          & > a {
            color: #a4a9a9;
            &:hover {
              background: #fff;
            }
            &.dropdown-toggle {
              &:hover {
                color: #4298cc;
              }
              &:focus {
                color: #4298cc;
              }
              &:active {
                color: #4298cc;
              }
              .fa-angle-down {
                margin-left: 12px;
                font-size: 20px;
                position: relative;
                top: 3px;
              }
            }

            & .label {
              font-family: $gravityBook-font;
              top: 10px;
              right: 21%;
              border-radius: 30%;
              max-width: 60px;
              padding: 6px 7px;
              box-sizing: border-box;
              font-size: 12px;
              font-weight: 100;
              overflow-x: hidden;
            }
            & .label-success {
              background-color: #f398b0 !important;
              font-family: $gravityBook-font;
            }
            & .label-warning {
              background-color: #54bece !important;
              font-family: $gravityBook-font;
            }
          }
        }
      }
    }
    .languages-menu {
      .lang-menu {
        padding: 26px 26px 11px;
        &:hover {
          background-color: #fff;
        }

        .flag:before {
          width: 22px;
          height: 22px;
          -webkit-background-size: 22px;
          background-size: 22px;
        }
      }
    }
    .language-switcher.no-names .dropdown-menu .flag:first-child {
      box-shadow: inset 0 4px 10px -7px rgba(0, 0, 0, 0.2);
    }
    .language-switcher.no-names .dropdown-menu .flag {
      padding: 13px 24px 8px 23px;
      max-width: 74px;
      .dropdown-menu {
        box-shadow: none;
      }
    }
    .logo {
      position: relative;
      z-index: 1001;
      box-sizing: border-box;
      height: 66px;
      overflow: hidden;
      line-height: 1;
      color: #000;
      width: 255px;
      font-size: 16px;
      background: #fff;
      box-shadow: -2px -5px 9px rgba(0, 0, 0, 0.2);

      &:after {
        content: '';
        display: block;
        width: 98%;
        height: 1px;
        background-color: #f3f6f6;
        margin: 65px auto 0;
        z-index: 100;
      }
      .logo-lg {
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 175px;
          height: 21px;
          background: url('../images/headerImg/logo-lg.png') no-repeat center;
          position: absolute;
          left: 20px;
          margin-top: 19px;
        }
      }
      .logo-mini {
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 36px;
          height: 21px;
          background: url('../images/headerImg/logo-mini.png') no-repeat center;
          position: absolute;
          left: 7px;
          margin-top: 22px;
        }
      }
    }
  }
  .fa-envelope-o:before,
  .fa-bell-o:before,
  .flag-ru:before,
  .flag-en:before {
    font-size: 22px;
  }
  .flag-en:before,
  .flag-ru:before {
    background-size: 22px 22px;
  }
  .flag:before {
    width: 22px;
    height: 22px;
  }
  .dropdown-menu {
    border: none;
    .item-lang {
      a {
        background: #fff;
        &:hover {
          background-color: #f3fbfb;
        }
      }
    }
  }
  .acceptance-user-menu {
    & > li {
      & > a {
        padding: 3px 25px;
      }
    }
  }
  .nav {
    & > li {
      & > a {
        padding: 10px 36px;
      }
    }
  }
  .navbar-nav > .messages-menu > .dropdown-menu > li.header {
    border-radius: inherit;
  }
  .navbar-nav {
    & > .notifications-menu,
    & > .messages-menu {
      & > .dropdown-menu {
        width: 348px;
        & > li {
          &.header {
            padding: 15px 10px 15px 30px;
            box-shadow: inset 0 1px 18px -12px rgba(0, 0, 0, .4);
            color: #555555;
            border-bottom-color: #f398b0;
            border-radius: inherit;
          }
        }
      }
    }
    & > .notifications-menu {
      & > .dropdown-menu {
        & > li {
          &.header {
            border-bottom-color: #54bece;
          }
        }
      }
    }
  }
  .navbar-nav {
    margin-right: 15px;
  }
  .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a,
  .navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a {
    padding: 18px 13px 10px 30px;
    border: inherit;
    position: relative;
    font-family: $gravityBook-font;
    color: #8e8e8e;
    &:before {
      content: '';
      display: block;
      width: 82%;
      height: 1px;
      background-color: #eed4f8;
      position: absolute;
      bottom: 0;
      left: 30px;
      z-index: 100;
    }
    &:hover {
      background-color: #dbf9f6;
    }
  }
  .navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a {
    padding: 20px 13px 20px 30px;
    white-space: normal;
    > .fa {
      display: none;
    }
    p {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      display: block;
      width: 82%;
      height: 1px;
      background-color: #dbf9f6;
      position: absolute;
      bottom: 0;
      left: 30px;
      z-index: 100;
    }
    &:after {
      display: none;
    }
    &:hover {
      background-color: #dbf9f6;
    }
  }
  .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4,
  .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > p {
    font-family: $gravityBook-font;
    font-size: 14px;
  }
  .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 {
    margin: 0 0 10px 0;
    color: #f398b0;
    & > small {
      color: #f398b0;
    }
  }
  .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > p {
    margin: 0;
    padding: 0 0 8px 0;
    color: #8e8e8e;
    font-family: $gravityBook-font;
    white-space: normal;
    line-height: 22px;
  }
  .main-header .logo:hover {
    background-color: #fff;
  }
  .navbar-static-top {
    height: 66px;
    max-height: 70px;
    &:after {
      content: '';
      width: 96%;
      display: block;
      height: 1px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      margin-left: -48%;
      top: 65px;
    }
    .ti-layout-placeholder {
      &:before {
        content: "\e6f4";
        color: #bec1c1;
        font-size: 22px;
      }
    }
    .notifications__button {
      .fa-bell-o {
        &:before {
          color: #bec1c1;
          font-size: 22px;
          position: relative;
          top: 0;
          left: 4px;
        }
      }

    }
  }
  &.sidebar-collapse {
    .navbar-static-top:after {
      width: 98.4%;
    }
  }
  .ti-align-right {
    font-size: 17px;
    margin-top: 5px;
    transition: .3s;
    border: none;
    padding: 0;
    color: #bec1c1;
    position: relative;
    top: 26px;
    left: 29px;
    &:before {
      content: "\e6c1";
    }
  }
  .main-header .navbar .nav > li > a.dropdown-toggle.e-user-head-dropdown {
    font-family: $gravityBook-font;
    color: #4298cc;
    letter-spacing: 0.03em;
    background-color: #eff3f3;
    padding: 26px 36px 20px 20px;
    min-width: 230px;
    max-width: 230px;
    box-sizing: border-box;
    display: block;
    position: relative;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    .ti-angle-down {
      position: relative;
    }
    &:hover {
      background-color: #fff;
    }
    .ti-angle {
      &:before {
        display: none;
      }
    }
    &:after {
      content: "\e64b";
      font-family: $themify;
      color: #bec1c1;
      position: absolute;
      right: 13px;
      top: 28px;
    }
  }
  .main-header .navbar .nav > li.dropdown.open {
    a.e-user-head-dropdown {
      &:after {
        content: "\e648";
      }
    }
  }
  .main-header .navbar .nav .open {
    > a {
      background-color: #fff;
      &:hover {
        background-color: #fff;
      }
      &:focus {
        background-color: #fff;
      }
      &:active {
        background-color: #fff;
      }
    }
    & .e-user-head-dropdown {
      background-color: #fff;
      &:focus {
        background-color: #fff;
      }
      &:active {
        background-color: #fff;
      }
    }
  }
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    left: 0;
  }
  .acceptance-user-menu > li > a {
    padding: 18px 9px;
    letter-spacing: 0.025em;
  }
  .ti-user {
    &:before {
      content: "\e602";
      color: #54bece;
      font-size: 16px;
      margin: 10px;
    }
  }
  .acceptance-user-menu {
    .ti-server {
      &:before {
        content: "\e67d";
        color: #54bece;
        font-size: 16px;
        margin: 10px;
      }
    }

    .ti-check-box {
      &:before {
        content: "\e64d";
        color: #54bece;
        font-size: 16px;
        margin: 10px;
      }
    }
    .ti-unlock {
      &:before {
        content: "\e603";
        color: #54bece;
        font-size: 16px;
        margin: 10px;
      }
    }
  }
  .dropdown-menu > li > a:hover {
    background-color: #f3fbfb;
  }
  .dropdown-menu > .divider {
    width: 76%;
    background-color: #dbf9f6;
    margin: 0 auto;
  }
  .dropdown-menu {
    border-radius: inherit;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
    padding: 0 0 5px 0;
  }
  .acceptance-user-menu__link-payment {
    box-shadow: inset -1px 13px 8px -14px rgba(0, 0, 0, 0.4);
  }
  .dropdown.messages-menu,
  .dropdown.notifications-menu {
    a {
      padding: 26px 28px 12px 27px;
    }
    .dropdown-menu {
      right: 0;
      left: auto !important;
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2)
    }
    .footer {
      height: auto;
      border-top: none;
      padding: 20px 12px 26px;
      background: #fff;
      text-align: center;
    }
  }
  .dropdown.notifications-menu {
    a {
      padding: 26px 30px 14px 21px;
    }
  }
  .main-header .languages-menu .dropdown-menu {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    max-width: 74px;
    width: 74px;
    min-width: inherit;
    .item-lang {
      width: 74px;
    }
  }
  .navbar-nav > .messages-menu > .dropdown-menu > li.footer > a,
  .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a {
    font-family: $gothamPro-reg-font;
    color: #ffffff !important;
    border-radius: 19px;
    background-color: #f398b0;
    text-transform: uppercase;
    border: 1px solid transparent;
    line-height: 1;
    padding: 11px 31px;
    letter-spacing: 0.025em;
    display: inline-block;
    transition: all .1s;
    font-size: 14px;
    &:hover {
      background-color: transparent;
      border: 1px solid #f398b0;
      color: #f398b0 !important;
    }
    &:focus {
      background-color: #f398b0;
      color: #fff !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    &:active {
      background-color: #f398b0;
      color: #fff !important;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
  }
  .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a {
    background-color: #00c0cd;
    &:hover {
      background-color: transparent;
      border: 1px solid #00c0cd;
      color: #00c0cd !important;
    }
    &:focus {
      background-color: #00c0cd;
      color: #fff !important;
    }
    &:active {
      background-color: #00c0cd;
      color: #fff !important;
    }
  }
  .e-switcher-site {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAAXElEQVQoU2NcumpV2n8GBpeYsLAwBjxgyapVqxgZGPYwgtQsXbmy/D8jozEuTWDF//+fjQ4P7wRrwKcJWTFIHVwDNk3oijE0IGsCS0Kdgew1FBuQnQdig9yMHg4AN2s4ZmQtLrkAAAAASUVORK5CYII=') 98% center no-repeat;
  }
  &.sidebar-collapse {
    .ti-align-right {
      left: 15px;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .e-partner {
    .navbar-static-top:after {
      margin-left: -47.3%;
    }
    &.sidebar-collapse {
      .navbar-static-top:after {
        width: 97.7%;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .e-partner {
    .main-header .navbar-custom-menu a {
      color: #555555;
      &:hover {
        color: #000;
      }
      &:focus {
        color: #000;
      }
      &:active {
        color: #000;
      }
    }
    .dropdown-menu.js-user-menu.acceptance-user-menu {
      width: 230px;
      right: 15px;
      left: inherit !important;
      border: none;
    }
    .dropdown.notifications-menu .dropdown-menu {
      right: 27px;
    }
    .navbar-nav > .notifications-menu > .dropdown-menu > li.header {
      box-shadow: none;
    }
    .e-partner .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a {
      background-color: #00c0cd !important;
    }
    .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a {
      background: #00c0cd !important;
      &:focus, &:active {
        background: #00c0cd !important;
        color: #fff !important;
      }
      &:hover {
        &:focus, &:active {
          background: #00c0cd !important;
          color: #fff !important;
        }
      }
    }
    .navbar-nav > .messages-menu > .dropdown-menu > li.footer > a {
      background: #f398b0 !important;
      &:focus, &:active {
        background: #f398b0 !important;
        color: #fff !important;
      }
      &:hover {
        &:focus, &:active {
          background: #f398b0 !important;
          color: #fff !important;
        }
      }
    }
    .main-header .languages-menu .lang-menu {
      padding: 26px 23px 11px 22px;
    }
    .dropdown.notifications-menu a {
      padding: 26px 25px 14px 16px;
    }
    .main-header .navbar .nav > li > a .label {
      right: 11px;
    }
    .dropdown.messages-menu a {
      padding: 26px 20px 12px 21px;
    }
    .dropdown.messages-menu .dropdown-menu {
      right: 90px;
      border: none;
    }
    .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a:hover {
      background-color: transparent !important;
    }
    .navbar-nav > .messages-menu > .dropdown-menu > li.footer > a:hover {
      background-color: transparent !important;
    }
    &.sidebar-collapse .navbar-static-top:after {
      width: 95.7%;
    }
    .navbar-static-top:after {
      width: 92%;
      left: 50%;
      margin-left: -46%;
    }
  }
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    border: none;
  }
  .e-partner .main-header .language-switcher.no-names .dropdown-menu .flag {
    max-width: 67px;
    width: 67px;
  }
  .e-partner .main-header .languages-menu .dropdown-menu {
    width: 67px;
  }
  .e-partner .main-header .languages-menu .dropdown-menu .item-lang {
    width: 67px;
  }
}

@media only screen and (max-width: 767px) {
  .e-partner {
    .main-header .logo {
      width: 100%;
      text-align: center;
      & .logo-lg {
        width: 100%;
        &:before {
          display: inline-block;
          left: 50%;
          margin-left: -87px;
        }
      }
    }
    .main-header {
      .navbar {
        margin-left: 0 !important;
      }
      .logo {
        &:after {
          display: none;
        }
      }
    }
    .navbar-nav .open .dropdown-menu > li > a {
      padding: 18px 10px;
      color: #555555;
      &:hover {
        background-color: #f3fbfb;
        color: #000;
      }
    }
    .navbar-nav .open .dropdown-menu {
      position: absolute;
      box-shadow: 0 4px 17px -11px rgba(0, 0, 0, .69);
    }
    &.skin-blue .main-header .navbar .dropdown-menu li.divider {
      background-color: #dbf9f6;
    }
    .navbar-static-top:after {
      width: 100%;
    }
    .dropdown {
      max-width: 240px;
      .e-user-head-dropdown {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .navbar-static-top:after {
      width: 93.8%;
      right: 50%;
      margin-right: -46.9%;
    }
    .main-header .navbar .nav .open .e-user-head-dropdown {
      box-shadow: none;
    }
  }
}

@media (max-width: 522px) {
  .e-partner .ti-align-right {
    left: 17px;
    top: 30px;
  }
}

@media (max-width: 479px) {
  .e-partner {
    .navbar-static-top {
      .ti-layout-placeholder {
        &::before {
          font-size: 18px;
        }
      }
      .notifications__button .fa-bell-o {
        &::before {
          font-size: 18px;
        }
      }
    }
    .main-header .languages-menu .lang-menu .flag:before {
      width: 20px;
      height: 20px;
    }
    .main-header .languages-menu .lang-menu {
      .flag {
        position: relative;
        top: 17px;
      }
    }
    .main-header .language-switcher.no-names .dropdown-menu .flag {
      max-width: 50px;
      height: 50px;
      padding: 13px 0 0 0;
    }
    .main-header .language-switcher.no-names .dropdown-menu .flag {

    }
    .dropdown.messages-menu a {
      height: 50px;
      width: 50px;
      padding: 0;
      i:before {
        position: relative;
        top: 19px;
        left: 16px;
      }
    }
    .dropdown.notifications-menu > a {
      height: 50px;
      width: 50px;
      padding: 0;
    }
    .main-header .navbar .nav > li > a .label {
      font-size: 10px;
      top: 4px;
      right: 6px;
    }
    .navbar-static-top .notifications__button .fa-bell-o:before {
      position: relative;
      top: 20px;
      left: 16px;
    }
    .main-header .languages-menu .lang-menu {
      height: 50px;
      width: 50px;
      padding: 0;
    }
    .main-header .navbar .nav > li > a.dropdown-toggle.e-user-head-dropdown {
      padding: 18px 33px 12px 12px;
      min-width: 124px;
      max-width: 170px;
      font-size: 12px;
    }
    .ti-align-right {
      left: 12px;
      top: 19px;
    }
    .main-header .navbar .nav > li > a.dropdown-toggle.e-user-head-dropdown:after {
      top: 17px;
    }
    .navbar-custom-menu > .navbar-nav {
      display: flex;
      margin-right: 0;
      > li:nth-last-child(n+2) {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
      }
      ul {
        li {
          display: block;
        }
      }
    }
    .main-header .logo .logo-lg:before {
      width: 140px;
      height: 17px;
      background-size: cover;
      margin-left: -70px;
      top: -8px;
    }
    .main-header .logo {
      height: 40px;
    }
    .navbar-static-top {
      height: 46px;
    }
    .navbar-static-top:after {
      width: 90%;
      right: 50%;
      margin-right: -45%;
      top: 50px;
    }
    .dropdown-menu.js-user-menu.acceptance-user-menu {
      right: 0;
    }
    .navbar-nav > .messages-menu > .dropdown-menu,
    .navbar-nav > .notifications-menu > .dropdown-menu {
      width: 290px;
    }
    .dropdown.notifications-menu .dropdown-menu {
      right: 15px;
    }
    .main-header .languages-menu .dropdown-menu,
    .main-header .languages-menu .dropdown-menu .item-lang {
      width: 50px;
    }
  }
}

@media (max-width: 362px) {
  .e-partner {
    .main-header .navbar .nav > li > a.dropdown-toggle.e-user-head-dropdown {
      max-width: 124px;
    }
  }
}

@media (min-width: 768px) {
  .e-partner {
    .navbar-nav {
      & > li {
        & > a {
          padding-top: 26px;
          padding-bottom: 24px;
        }
      }
    }
  }
}







