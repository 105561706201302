@import "_variables.scss";

.e-partner {
  .e-referrals-form {
    .input-group-addon {
      border-right: none;
      border-radius: 5px 0 0 5px;
    }
    .glyphicon-envelope {
      color: #e4e7e7;
    }
    .box-body {
      > .form-group {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    .referrals-form {
      margin-bottom: 35px;
    }
    .e-referrals-send-btn {
      text-transform: uppercase;
      font-family: $gotham-light-font;
      font-size: 14px;
      background-color: #53dccd;
      border-color: #53dccd;
      transition: all .1s;
      margin-left: 30px;
      border-radius: 19px;
      padding: 6px 20px;
      &:hover {
        border-color: #53dccd;
        color: #53dccd;
        background-color: transparent;
      }
      &:focus {
        border-color: #53dccd;
        color: #fff;
        background-color: #53dccd;
      }
      &:active {
        border-color: #53dccd;
        color: #fff;
        background-color: #53dccd;
      }
    }
  }
  .e-referral-link {
    border-color: #e4e7e7;
    border-radius: 0 5px 5px 0;
    font-size: 0 !important;
    position: relative;
    &:before {
      content: attr(data-hreftext);
      font-size: 14px;
      position: absolute;
      top: 10px;
      margin-top: -5px;
      left: 5px;
    }
  }
  .e-referrals-form {
    max-width: 35%;
    min-width: 500px;
  }
  .field-invitationform-email {
    input {
      border-radius: 0 5px 5px 0 !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .e-partner {
    .e-referral-link {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .e-referrals-form {
      min-width: inherit;
      width: 100%;
      max-width: inherit;
    }
  }
}
@media only screen and (max-width: 767px) {
  .e-partner {
    .e-refs-table {
      table {
        tbody {
          td {
            word-break: break-all;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .e-partner {
    .e-websites {
      .e-referral-link {
        display: block;
        margin-bottom: 36px;
        border-radius: 5px;
      }
      .acceptance-websites {
        > .form-group {
          > .input-group {
            display: block;
            width: 100%;
            > .input-group-addon {
              width: 100%;
              border-right: 1px solid #e4e7e7;
              border-radius: 5px;
              display: flex;
              height: 40px;
              align-items: center;
              margin-bottom: 20px;
              overflow-x: hidden;
            }
          }
        }
      }
      .nav-tabs-custom>.nav-tabs>li {
        margin-right: 0;
      }
    }
    .e-referrals-form .e-referrals-send-btn {
      margin-left: 10px;
    }
    .e-referrals-form {
      margin-bottom: 36px;
    }
  }
  .e-partner .e-websites .nav-tabs-custom .e-nav-websites-refs li a {
    padding: 32px 15px 10px;
    font-size: 16px;
  }
}
