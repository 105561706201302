@import "_variables.scss";

.e-partner {
  .e-theme-fx {
    display: flex;
    margin-bottom: 30px;
    padding-left: 11px;
  }
  .e-theme-banner-btn {
    margin-top: auto;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;

    a {
      display: inline-block;
      font-family: $gotham-light-font;
      color: #6fa7c8;
      font-size: 14px;
      text-transform: uppercase;
      border-radius: 19px;
      border: 1px solid #6fa7c8;
      padding: 12px 18px 11px 43px;
      position: relative;
      line-height: 1;
      white-space: nowrap;
      transition: all .1s;
      &:before {
        content: "\f407";
        font-family: $ionicons;
        font-size: 22px;
        position: absolute;
        left: 15px;
        top: 9px
      }
      &:hover {
        color: #fff;
        border-color: #6fa7c8;
        background-color: #6fa7c8;
      }
      &:focus,
      &:active {
        color: #fff;
        border-color: #6fa7c8;
        background-color: #6fa7c8;
        box-shadow: inset 2px 2px 6px rgba(0, 0, 0, .3);
      }
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  .e-banner-item {
    max-width: 58%;
    flex-basis: 58%;
    padding-right: 15px;
    padding-left: 15px;
    img {
      border: 1px solid #e4e7e7;
    }
  }
  .e-description-item {
    display: flex;
    flex-direction: column;
    padding: 30px 15px 0 30px;
    max-width: 34%;
    flex-basis: 34%;
  }
  .e-theme-banner-description {
    font-family: $gravityBook-font;
    color: #555555;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .e-description-content {
    flex: 1 0 auto;
  }
  .e-theme-filters {
    max-width: 79%;
    margin-bottom: 36px;
    padding-left: 11px;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAAXElEQVQoU2NcumpV2n8GBpeYsLAwBjxgyapVqxgZGPYwgtQsXbmy/D8jozEuTWDF//+fjQ4P7wRrwKcJWTFIHVwDNk3oijE0IGsCS0Kdgew1FBuQnQdig9yMHg4AN2s4ZmQtLrkAAAAASUVORK5CYII=') 96% center no-repeat;
    }
    select::-ms-expand {
      display: none;
    }
  }
  .e-theme-banner-head-text {
    margin-bottom: 40px;
    padding: 10px 22px 0 11px;
    width: 70%;
    color: #555555;
    font-family: $gravityBook-font;
    font-size: 14px;
    line-height: 22px;
  }
  .e-theme-banner-title {
    color: #555555;
    font-family: $gothaProBold-font;
    font-size: 22px;
    margin-bottom: 46px;
  }
}

@media only screen and (max-width: 1540px) {
  .e-partner {
    .e-description-item {
      max-width: 40%;
      flex-basis: 40%;
    }
    .e-theme-filters {
      max-width: 93%;
    }
    .e-theme-banner-btn {
      display: block;
    }
    .e-theme-banner-btn a {
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .e-partner {
    .e-theme-banner-head-text {
      width: 100%;
    }
    .e-theme-filters {
      max-width: inherit;
      margin-right: -16px;
      margin-left: -16px;
      padding-left: 0;
    }
    .e-theme-fx {
      display: block;
      padding-left: 0;
      margin-bottom: 62px;
    }
    .e-banner-item, .e-description-item {
      max-width: inherit;
      flex-basis: auto;
    }
    .e-description-item {
      padding-left: 15px;
    }
    .e-theme-banner-title {
      margin-bottom: 14px;
    }
    .e-theme-banner-head-text {
      padding-left: 0;
    }
  }
}