@import "_variables";

.e-partner {
  table {
    visibility: visible;
  }
  .table-bordered > tfoot > tr > td {
    white-space: nowrap;
    padding: 20px 16px 20px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #54bece;
    font-family: $gravityBook-font;
    line-height: 1;
    &:first-child {
      color: #54bece;
      font-size: 16px;
      letter-spacing: 0.025em;
      text-align: right;
      padding-right: 20px;
    }
  }
  .table-bordered {
    border: none;
  }
  .table-striped > tbody > tr:nth-of-type(odd) {
    background: transparent;
  }
  .table-bordered > tbody > tr > td {
    vertical-align: middle;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #f0f3f3;
    padding: 20px 16px 20px;
    color: #555555;
    font-family: $gravityBook-font;
    letter-spacing: 0.025em;
    max-width: 250px;
    a {
      font-family: $gravityBook-font;
    }
    &:first-child {
      padding-left: 0;
      white-space: nowrap;
      a {
        color: #4298cc;
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }
  .box-header.with-border {
    border-bottom: none;
  }
  .table-bordered > thead > tr > th {
    &:first-child {
      padding-left: 0 !important;
    }
  }
  .table-bordered > tbody > tr:last-child > td {
    border-bottom-color: #54bece;
  }
  .table > tbody > tr.danger > td {
    background: transparent;
  }
  table.table-bordered thead tr th {
    vertical-align: middle;
    border: none;
  }
  .table-bordered > thead > tr {
    &:last-child {
      th {
        border-bottom: 2px solid #54bece;
        padding: 14px 40px 8px 8px;
        position: relative;
        &:after {
          content: '';
          width: 1px;
          height: 75%;
          display: block;
          position: absolute;
          right: 0;
          top: 12%;
          background-color: #e5e9e9;
        }
      }
    }
    &:first-child {
      a {
        &:after {
          content: "\f3d4";
          display: block;
          font-family: $ionicons;
          position: absolute;
          top: 0;
          margin-top: -7px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 20px;
          margin-bottom: 20px;
          color: #ccc;
          height: 28px;
          padding: 0;
        }
        &:before {
          content: "\f3d7";
          display: block;
          font-family: $ionicons;
          position: absolute;
          top: 0;
          margin-top: -7px;
          left: 50%;
          margin-left: 10px;
          transform: translateX(-50%);
          margin-bottom: 20px;
          font-size: 20px;
          color: #ccc;
          height: 28px;
        }
        &.asc {
          &:after {
            margin-top: 0px;
            color: #ccc;
          }
          &:before {
            content: "\f3d7";
            color: #000;
          }
        }
        &.desc {
          &:after {
            margin-top: 0px;
            color: #000;
          }
          &:before {
            content: "\f3d7";
            color: #ccc;
          }
        }
      }
    }
  }
  .table-bordered > thead > tr > th {
    border-left: none;
    border-right: none;
    font-family: $gothamPro-reg-font;
    color: #555555;
    font-size: 14px;
    letter-spacing: 0.025em;
    font-weight: 100;
    position: relative;
    padding-right: 10px;
    max-width: 250px;
    a {
      color: #555555;
      outline-width: 0;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:nth-child(n+2) {
      padding-left: 16px;
    }

    &.e-th-middle {
      padding-right: 16px;
    }
  }
}

@media (max-width: 991px) {
  .e-partner {
    .table-bordered > tbody > tr > td {
      padding: 15px 0;
      max-width: inherit;
      border-top: none;
      border-bottom: 1px solid #f0f3f3;
      &:first-child {
        border-bottom: 2px solid #54bece;
      }
    }
    table.table-bordered {
      tfoot {
        display: none;
      }
    }

    .table-bordered > tbody > tr > td:first-child a {
      white-space: pre-wrap;
    }
    table.table-bordered {
      thead {
        tr + tr {
          display: none;
          th {
            display: none;
          }
        }
      }
    }

    table.table-bordered thead {
      display: none;
    }
    table.table-bordered tbody td {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: pre-wrap;
      word-break: break-all;
      span {
        white-space: pre-line;
      }
    }

    table.table-bordered tbody td:before {
      content: attr(data-th);
      display: inline-block;
      max-width: 40%;
      flex-basis: 40%;
      word-break: break-all;
      white-space: pre-line;
      margin-right: 12px;
      min-width: 120px;
    }
    .e-refs-table table.table-bordered tbody td:before {
      content: attr(data-td);
      display: inline-block;
      max-width: 40%;
      flex-basis: 40%;
      word-break: break-all;
      white-space: pre-line;
      margin-right: 12px;
      min-width: 120px;
    }
    .table-bordered > tbody > tr:last-child > td {
      border-bottom: 1px solid #f0f3f3;
      border-top: none;
      word-break: break-all;
      white-space: pre-line;
      &:first-child {
        border-bottom: 2px solid #54bece;
      }
    }
    tbody tr td:last-child {
      margin-bottom: 26px;
      white-space: pre-wrap;
      word-break: break-all;
    }
    .e-mobile-none {
      display: none !important;
    }
  }
}
@media (max-width: 321px){
  .e-partner .e-refs-table table.table-bordered tbody td:before{
    min-width: 90px !important;
  }
  .btn.btn-success.btn-sm.e-website-approve-btn.acceptance-website-approve{
    max-width: 126px;
    padding: 11px 5px;
  }
  .e-partner .e-website-approve-btn{
    //max-width: 130px;
  }
  .btn-group{
    display: flex;
  }
  .dropdown-menu{
    left: -30px;
  }
  .e-partner table.table-bordered tbody td:before{
    min-width: 90px;
  }
  .e-partner{
    table.table-bordered{
      tbody{
        td{
          justify-content: flex-start;
        }
      }
    }
  }
}

