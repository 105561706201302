@import "_variables.scss";

.e-partner {
  #details-order-statistics,
  #referral-statistics {
    table {
      thead {
        tr {
          &:first-child {
            th {
              &.e-th-double {
                padding-left: 8px;
                padding-right: 8px;
                text-align: center;
                font-weight: bold;
                border-bottom: none;
              }
              border-bottom: 2px solid #54bece;
              &:after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 10px;
                width: 1px;
                height: 59px;
                background-color: #e5e9e9;
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
            }
          }
          th {
            vertical-align: bottom;
          }
          &:last-child {
            th {
              padding-left: 16px !important;
              a {
                &:after {
                  content: "\f3d4";
                  display: block;
                  font-family: $ionicons;
                  position: absolute;
                  top: 50%;
                  margin-top: -14px;
                  right: 23px;
                  font-size: 20px;
                  color: #ccc;
                  height: 28px;
                  padding: 0;
                }
                &:before {
                  content: "\f3d7";
                  display: block;
                  font-family: $ionicons;
                  position: absolute;
                  top: 50%;
                  margin-top: -14px;
                  right: 15px;
                  font-size: 20px;
                  color: #ccc;
                  height: 28px;
                }
                &.asc {
                  &:after {
                    margin-top: -10px;
                    color: #ccc;
                  }
                  &:before {
                    content: "\f3d7";
                    color: #000;
                  }
                }
                &.desc {
                  &:after {
                    margin-top: -10px;
                    color: #000;
                  }
                  &:before {
                    content: "\f3d7";
                    color: #ccc;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #details-order-statistics,
  #referral-statistics,
  #date-statistics {
    table {
      tbody {
        tr {
          td:first-child {
            a {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1800px) {
  .e-partner {
    #details-order-statistics,
    #referral-statistics,
    #date-statistics {
      .grid-view {
        overflow-x: auto;
        visibility: hidden;
        &:hover {
          visibility: visible;
        }
        &:focus {
          visibility: visible;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .e-partner {
    #details-order-statistics,
    #referral-statistics,
    #date-statistics {
      table {
        tbody {
          tr {
            td:first-child {
              a {
                white-space: pre-wrap;
              }
            }
          }
        }
      }
    }
  }
}