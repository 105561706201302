@import "_variables.scss";

.e-partner {
  .e-view-ticket-table {
    margin-bottom: 40px;
     &.table-bordered>tbody>tr:nth-child(n+2)>td {
      border-color: transparent;
    }
    &.table-bordered>tbody>tr:last-child>td {
      border-bottom-color: #f0f3f3;
    }
    &.table-bordered>tbody>tr:nth-child(n+2) {
      td:first-child {
        color: #54bece;
        font-family: $gothamPro-reg-font;
      }
    }
  }

  #comment-pjax-container1 {
    .h3-body-title {
      color: #555555;
      font-family: $gotham-light-font;
      font-size: 22px;
    }
    input[type='file'] {
      display: none;
    }

    .field-comment-file {
      label {
        background: none;
        box-shadow: none;
        background: 0 0;
        font-family: GothaProReg,sans-serif;
        font-size: 14px;
        text-transform: uppercase;
        color: #54bece;
        border: 1px solid #3edccd;
        border-radius: 20px;
        line-height: 1;
        padding: 13px 21px 10px 39px;
        transition: all .1s;
        position: relative;
        margin-bottom: 0;
        cursor: pointer;
        &:before {
          content: "\f367";
          font-family: $ionicons;
          position: absolute;
          left: 16px;
          top: 50%;
          font-size: 18px;
          margin-top: -9px;
        }
        &:hover {
          color: #fff;
          background-color: #3edccd;
        }
      }
    }
    .comment-box-partial {
      text-align: center;
      .btn.btn-primary {
        background-color: #3edccd;
        border: 1px solid transparent;
        border-radius: 20px;
        transition: all .1s;
        margin-top: 10px;
        text-transform: uppercase;
        padding: 12px 30px 10px;
        line-height: 1;
        &:hover {
          color: #3edccd;
          border-color: #3edccd;
          background-color: transparent;
        }
        &:focus {
          color: #fff;
          border-color: #3edccd;
          background-color: #3edccd;
        }
        &:active {
          color: #fff;
          border-color: #3edccd;
          background-color: #3edccd;
        }
      }
    }
  }
  .e-ticket-resolve-btn {
    background-color: #3edccd;
    border: 1px solid transparent;
    border-radius: 20px;
    transition: all .1s;
    margin-top: 10px;
    text-transform: uppercase;
    padding: 11px 15px 8px 41px;
    line-height: 1;
    margin-bottom: 30px;
    position: relative;
    &:before {
      content: "\f375";
      font-family: $ionicons;
      font-size: 20px;
      position: absolute;
      top: 8px;
      left: 15px;
    }
    &:hover {
      color: #3edccd;
      background-color: transparent;
      border-color: #3edccd;
    }
    &:focus {
      color: #fff;
      background-color: #3edccd;
      border-color: #3edccd;
    }
    &:active {
      color: #fff;
      background-color: #3edccd;
      border-color: #3edccd;
    }
  }
}

@media (max-width: 991px) {
  .e-partner {
    table.table-bordered tbody td.e-non-responsive {
      word-break: break-all;
      vertical-align: top;
      &:last-child {
        margin-bottom: 0;
        max-width: 65%;
        text-align: right;
      }
      &:first-child {
        border-bottom-width: 1px;
        min-width: 120px;
        margin-right: 5px;
        word-break: break-all;
        white-space: pre-wrap;
      }
      &:before {
        display: none;
      }
    }
    .e-non-responsive-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .e-view-ticket-table.table-bordered>tbody>tr:last-child>td {
        border-bottom: none;
    }
  }
}