@import "_variables";

.e-partner {
  .pagination {
    visibility: visible;
    margin: 30px 0 5px !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      display: inline-block;
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        a {
          color: #a4a9a9;
          &:hover {
            color: #a4a9a9;
            background: none;
          }
        }
      }
      a {
        background: none;
        border: none;
        font-family: $gravityBook-font;
        color: #4298cc;
        font-size: 14px;
        line-height: 1;
        padding: 0;
        margin: 0;
        &:focus {
          background: none;
        }
      }
    }
    .prev {
      span {
        display: flex;
        align-items: center;
        position: relative;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        color: transparent;
        border: 1px solid #e5e9e9;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        &:hover {
          &:after {
            color: #4298cc;
          }
        }
        &:after {
          content: "\e64a";
          display: block;
          font-family: $themify;
          position: absolute;
          top: 50%;
          left: 50%;
          color: #a4a9a9;
          font-size: 12px;
          margin-left: -6px;
          margin-top: -5px;
          line-height: 1;
        }
      }
      a {
        background: #fff;
        width: 28px;
        height: 28px;
        position: relative;
        border-radius: 50%;
        color: transparent;
        border: 1px solid #e5e9e9;
        font-size: 12px;
        margin-right: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        &:hover {
          &:after {
            color: #4298cc;
          }
        }
        &:focus,
        &:active {
          background: #fff;
        }
        &:after {
          content: "\e64a";
          display: block;
          font-family: $themify;
          position: absolute;
          top: 50%;
          left: 50%;
          color: #a4a9a9;
          font-size: 12px;
          margin-left: -6px;
          margin-top: -5px;
        }
      }
    }
    .next {
      a {
        background: #fff;
        width: 28px;
        height: 28px;
        position: relative;
        border-radius: 50%;
        color: transparent;
        border: 1px solid #e5e9e9;
        font-size: 12px;
        margin-right: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        &:hover {
          &:after {
            color: #4298cc;
          }
        }
        &:focus,
        &:active {
          background: #fff;
        }
        &:after {
          content: "\e649";
          display: block;
          font-family: $themify;
          position: absolute;
          top: 50%;
          left: 50%;
          color: #a4a9a9;
          margin-left: -6px;
          margin-top: -5px;
          font-size: 12px;
          line-height: 1;
        }
      }
      span {
        position: relative;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        color: transparent;
        border: 1px solid #e5e9e9;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        &:hover {
          &:after {
            color: #4298cc;
          }
        }
        &:after {
          content: "\e649";
          display: block;
          font-family: $themify;
          position: absolute;
          top: 50%;
          left: 50%;
          color: #a4a9a9;
          font-size: 12px;
          margin-left: -6px;
          margin-top: -5px;
          line-height: 1;
        }
      }
    }
  }
}