@import "_variables";

.e-partner {
  .breadcrumb {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    li {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-family: $gravityBook-font;
      color: #555555;
      font-size: 14px;
      &:before {

      }
      a {
        font-family: $gravityBook-font;
        color: #4298cc;
        font-size: 14px;
      }
      & + li {
        &:before {
          content: "\f105";
          font-family: 'fontawesome';
          color: #555555;
          margin-right: 2px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .e-partner {
    .content-header>.breadcrumb {
      background: none;
      padding-left: 0;
    }
  }
}