@import "_variables.scss";

.e-partner {
  .comment-section {
    .comment {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      p {
        width: 75%;
        font-family: $gravityBook-font;
        line-height: 20px;
        color: #555555;
        background-color: #dbf9f6;
        border-radius: 8px;
        &:after {
          content: '';
          width: 0;
          height: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-left: 14px solid #dbf9f6;
          border-radius: inherit;
          background-color: transparent;
          border-right: none;
          top: 50%;
          right: -12px;
          margin-top: -10px;

        }
      }
      .avatar {
        display: none;
      }
      .info {
        width: 22%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -20px;
        a {
          font-family: $gravityBook-font;
          font-size: 14px;
          color: #555555;
          font-weight: normal;
        }
        span {
          font-family: $gravityBook-font;
          color: #a4a9a9;
          font-size: 12px;
          &:before {
            content: "\e72b";
            font-family: $themify;
            margin-right: 5px;
          }
        }
      }
    }
    li.admin-comment {
      justify-content: space-between;
      p {
        background-color: #e5e9e9;
        &:after {
          width: 0;
          height: 0;
          border-left: none;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-right: 14px solid #e5e9e9;
          top: 50%;
          left: -12px;
          margin-top: -10px;
        }
      }
      .info {
        a {
          text-align: right;
        }
        span {
          text-align: right;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .e-partner .comment-section li.admin-comment p:after {
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 14px solid #e5e9e9;
    -webkit-border-radius: inherit;
    border-radius: inherit;
    background-color: transparent;
    border-right: none;
    top: 50%;
    left: 99.4%;
    margin-top: -10px;
  }
  .comment.admin-comment p:after, .comment.author-comment p:after {
    left: inherit;
  }
  .e-partner {
    .comment-section {
      padding-left: 0;
      margin-top: 46px;
    }
  }
  .e-partner .comment-section .comment p {
    width: 66%;
    padding: 12px;
  }
  .e-partner .comment-section .comment .info {
    width: 26%;
  }
}

