@import "_variables.scss";

.e-partner {
  .e-keywords-head-text {
    margin-bottom: 66px;
    margin-top: 10px;
    p {
      padding: 10px 22px 0 0;
      font-family: $gravityBook-font;
      color: #555555;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .e-keywords-list {
    ul {
      padding: 0;
      list-style-type: none;
      margin-bottom: 40px;
      li {
        &:first-child {
          span {
            font-family: $gothamPro-reg-font;
            color: #54bece;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px;
            display: block;
          }
        }
        a {
          font-family: $gravityBook-font;
          color: #4298cc;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 10px;
          display: block;
          word-wrap: break-word;
        }
      }
    }
  }

  .e-keywords-tab {
    display: flex;
    align-items: flex-start;
    width: 78%;
    ul {
      flex-basis: 200px;
      max-width: 200px;
      background-color: #fff;
      box-sizing: border-box;
      box-shadow: 0 5px 16px 1px rgba(0, 0, 0, 0.06), 0 1px 9px rgba(0, 0, 0, 0.03);
      li {
        box-shadow: inset -8px 8px 34px -15px rgba(0, 0, 0, .1);
        margin: 0;
        a {
          border: none;
          font-family: $gothamPro-reg-font;
          color: #555555;
          font-size: 14px;
          margin: 0;
          border-radius: inherit;
          transition: all .1s;
          padding: 13px 36px 13px 23px;
          &:hover {
            background: none;
            color: #54bece;
          }
        }
        &.active {
          box-shadow: none;
          a {
            border: none;
            color: #54bece;
          }
        }
      }
    }
    .e-inner-text {
      font-family: $gravityBook-font;
      font-size: 14px;
      color: #555555;
      line-height: 22px;
    }
    &.nav-tabs {
      border: none;
    }
    .nav-tabs {
      border: none;
    }
    .tab-content {
      box-shadow: 0 8px 12px -5px rgba(0, 0, 0, .06), 0 2px 0px rgba(0, 0, 0, .03);
      flex: 1;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 0 5px 5px;
      padding: 35px 3% 32px 3%;
    }
  }
  .e-keywords-tab-box {
    background-color: #eff3f3;
    border-radius: 0 5px 5px 0;
    box-shadow: none;
    .box-body {
      padding: 0 0 34px 0;
    }
  }
  .e-keywords-box {
    width: 78%;
  }
}

@media only screen and (max-width: 1365px) {
  .e-partner {
    .e-keywords-box,
    .e-keywords-tab {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .e-partner {
    .e-keywords-tab {
      display: block;
      ul {
        flex-basis: inherit;
        max-width: 100%;
        li {
          box-shadow: inset -2px 8px 34px -15px rgba(0, 0, 0, .1);
        }
      }
      .tab-content {
        padding: 20px 36px 20px 21px;
        border-radius: 0 0 5px 5px;
      }
    }
    .e-keywords-tab ul li a {
      padding: 13px 36px 13px 21px;
    }
  }
}
@media only screen and (max-width: 479px) {
  .e-partner {
    .e-keywords-tab ul li a {
      padding: 13px 36px 13px 15px;
    }
    .e-keywords-tab .tab-content {
      padding: 20px 36px 20px 15px;
    }
  }
}