@import "_variables.scss";

.e-partner {
  .calendar-time {
    margin-top: 10px;
    .fa-clock-o:before {
      color: #ccc;
    }
    .hourselect,
    .minuteselect,
    .ampmselect {
      background: none;
      border: none;
      font-size: 14px;
      color: #555555;
      font-family: $gothamPro-reg-font;
      -webkit-appearance: none;
      -moz-appearance:    none;
      appearance:         none;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAAXElEQVQoU2NcumpV2n8GBpeYsLAwBjxgyapVqxgZGPYwgtQsXbmy/D8jozEuTWDF//+fjQ4P7wRrwKcJWTFIHVwDNk3oijE0IGsCS0Kdgew1FBuQnQdig9yMHg4AN2s4ZmQtLrkAAAAASUVORK5CYII=')  no-repeat
      78% center;
      background-size: 10px;
    }
    .hourselect {
      background-position: 59% center;
    }
    .minuteselect {
      background-position: 76% center;
    }
  }
  .daterangepicker.opensleft:after {
    display: none;
  }
}