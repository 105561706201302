@import "_variables";

.static-box-message {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}

.static-box {
  width: 360px;
  margin: 7% auto;
}

.static-box-body {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
}

.static-box-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}

.static-box-header {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}

.e-partner {
  .box {
    border-radius: 12px;
    margin-bottom: 24px;
    box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.05);
    border-top: none;
  }
  .box-header {
    padding: 40px 22px 10px 21px;
  }
  .box-body {
    padding: 14px 22px 34px 21px;
  }
  .box-title {
    font-family: $gotham-light-font;
    font-weight: 400;
    color: #555555;
    font-size: 22px;
    letter-spacing: 0.025em;
  }
}

@media (max-width: 479px) {
  .e-partner {
    .box-body {
      padding: 14px 12px 34px 12px;
    }

  }
}
