@import "_variables";

.e-partner {
  .e-table-transaction {
    visibility: visible;
    > thead > tr.filters > td:nth-last-child(2):before {
      display: none;
    }
    thead {
      th {
        position: relative;
        z-index: 10;
      }
    }
    .filters {
      td {
        &:nth-child(-n +3) {
          &:after {
            display: none;
          }
        }
      }
    }
    th {
      padding-right: 40px;
    }
    &.table > thead > tr.filters > td {
      padding-left: 16px;
      padding-right: 16px;
    }

    .e-transaction_log-th {
      top: 53px;

      &:after {
        content: '';
        width: 1px;
        height: 75%;
        display: block;
        position: absolute;
        right: 0;
        top: 12%;
        background-color: #e5e9e9;
      }
    }
    tbody {
      tr {
        td {
          &:last-child {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .e-partner {
    .e-table-transaction {
      tbody {
        tr {
          td:last-child {
            white-space: pre-line;
            word-break: break-all;
          }
        }
      }
    }
  }
}
