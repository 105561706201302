@import "_variables";

.e-partner {
  .table > thead > tr.filters > td {
    border-top: none;
    border-right: none;
    border-left: none;
    border-color: #54bece;
    padding: 8px 16px 24px;
    position: relative;
    max-width: 250px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: -3px;
      width: 1px;
      height: 59px;
      background-color: #e5e9e9;
    }
  }
  .filters {
    select {
      font-size: 14px;
      border-radius: 5px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-color: #e4e7e7;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAAXElEQVQoU2NcumpV2n8GBpeYsLAwBjxgyapVqxgZGPYwgtQsXbmy/D8jozEuTWDF//+fjQ4P7wRrwKcJWTFIHVwDNk3oijE0IGsCS0Kdgew1FBuQnQdig9yMHg4AN2s4ZmQtLrkAAAAASUVORK5CYII=') 90% center no-repeat;
      &:focus {
        border-color: #d2d6de;
      }
    }
    input {
      border-color: #e4e7e7;
    }
  }
}