@import "_variables.scss";

.e-partner {
  .e-websites {
    .modal-dialog {
      width: 800px;
    }
    .modal-content {
      box-shadow: 0 5px 16px 1px rgba(0, 0, 0, 0.43), 0 1px 9px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
    .modal-title {
      font-family: $gotham-light-font;
      color: #000;
      font-size: 22px;
    }
    .modal-header {
      border: none;
      padding: 30px 30px 5px;
      .close {
        margin-top: -10px;
        font-weight: 100;
        font-size: 30px;
        color: #a4a9a9;
        transition: all .1s;
        font-family: $gotham-light-font;
      }
    }
    .modal-body {
      padding: 15px 30px 10px;
      p {
        font-family: $gothamPro-reg-font;
        font-size: 14px;
      }
      pre {
        background-color: #e5e9e9;
        border-radius: 5px;
        border-color: #e5e9e9;
        padding: 10px 80px 10px 30px;
        code {
          font-family: $gravityBook-font;
          font-size: 14px;
          color: #555555;
        }
      }
    }
    .modal-footer {
      padding: 5px 30px 44px;
      border: none;
      text-align: center;
    }
  }
  .e-callout-info {
    background-color: #e4fbf9 !important;
    border-radius: 5px;
    border: none;
    padding: 34px 44px 28px 42px;
    h4 {
      font-family: $gotham-light-font;
      color: #54bece;
      font-weight: 100;
      font-size: 17px;
      line-height: 24px;
      position: relative;
    }
    .t-info {
      position: absolute;
      left: -25px;
      top: 1px;
      &:before {
        content: "\e717";
        font-family: $themify;
        font-size: 14px;
      }
    }
    ol {
      li {
        font-family: $gotham-light-font;
        color: #54bece;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
  .e-site-form {
    .hint-block {
      font-family: $gravityBook-font;
      font-size: 12px;
      color: #a4a9a9;
    }
    .input-group-btn {
      position: relative;
      select {
        width: 84px;
        border-color: #e4e7e7;
        background-color: #ffffff;
        font-family: $gravityBook-font;
        font-size: 14px;
        color: #555555;
        height: 33px;
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAAXElEQVQoU2NcumpV2n8GBpeYsLAwBjxgyapVqxgZGPYwgtQsXbmy/D8jozEuTWDF//+fjQ4P7wRrwKcJWTFIHVwDNk3oijE0IGsCS0Kdgew1FBuQnQdig9yMHg4AN2s4ZmQtLrkAAAAASUVORK5CYII=') 90% center no-repeat;
        &:focus {
          box-shadow: none;
        }
        &:active {
          box-shadow: none;
        }
      }
      select::-ms-expand {
        display: none;
      }
    }
    input {
      max-height: 33px;
      border-color: #e4e7e7;
      font-family: $gravityBook-font;
      font-size: 14px;
      color: #555555;
    }
    .form-group.field-domain {
      margin-bottom: 25px;
    }
    .help-block {
      font-size: 14px;
    }
  }
  .e-website-save {
    font-family: $gotham-light-font;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #53dccd;
    border-radius: 19px;
    border-color: #53dccd;
    padding: 8px 30px;
    transition: all .1s;
    &:hover {
      border-color: #53dccd;
      background-color: transparent;
      color: #53dccd;
    }
    &:focus {
      border-color: #53dccd;
      background-color: #53dccd;
      color: #fff;
    }
    &:active {
      border-color: #53dccd;
      background-color: #53dccd;
      color: #fff;
    }
  }
  .e-modal-copy-btn {
    font-family: $gotham-light-font;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #6fa7c8;
    border-radius: 19px;
    border-color: #6fa7c8;
    padding: 8px 30px;
    transition: all .1s;
    &:hover {
      border-color: #6fa7c8;
      background-color: transparent;
      color: #6fa7c8;
    }
    &:focus {
      border-color: #6fa7c8;
      background-color: #6fa7c8;
      color: #fff;
    }
    &:active {
      border-color: #6fa7c8;
      background-color: #6fa7c8;
      color: #fff;
    }
  }

  .www-text-notice {
    padding: 15px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 991px) {
  .e-partner .e-websites .modal-dialog {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .e-partner {
    .e-websites {
      .modal-dialog {
        width: 94%;
        margin: 30px auto;
      }
      .modal-body {
        padding: 15px 15px 10px;
      }
    }
    .e-callout-info {
      padding: 20px 17px 14px 34px;
      h4 {
        font-size: 15px;
      }
      ol {
        li {
          font-size: 13px;
        }
      }
    }
  }
  .e-partner .e-websites .modal-body pre {
    padding: 10px 15px 10px 15px;
  }
}