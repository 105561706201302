@import "_variables";

.e-partner {
  .e-news-box {
    border-radius: 12px 10px 10px;
    box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.05);
    height: 320px;
  }
  .e-news-data {
    font-family: $gotham-light-font;
    font-size: 14px;
    color: #a4a9a9;
    display: inline-block;
    margin-bottom: 30px;
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-image: linear-gradient(to right, #54bece 0%, #5bd6d7 48%, #62f0e1 100%);
      opacity: .81;
      margin-top: 8px;
    }
  }
  .e-news-title {
    display: block;
    a {
      font-family: $gotham-light-font;
      color: #54bece;
      font-size: 18px;
    }
  }
  .box.box-solid > .box-header {
    .e-news-title {
      a {
        transition: color .1s;
        word-break: break-all;
        &:hover {
          background: none;
          color: #81e9f9;
        }
      }
    }
  }
  .e-news-body {
    font-family: $gravityBook-font;
    color: #555555;
    font-size: 14px;
    line-height: 24px;
    padding: 14px 22px 34px 0;
  }
  .e-read-btn {
    background: 0 0;
    font-family: $gothamPro-reg-font;
    font-size: 14px;
    text-transform: uppercase;
    color: #54bece;
    border: 1px solid #62f0e1;
    border-radius: 15px;
    line-height: 1;
    padding: 10px 14px 7px 39px;
    display: inline-block;
    transition: all .1s;
    position: relative;
    white-space: nowrap;
  }
  .box.box-solid > .box-header {
    .e-read-btn {
      position: relative;
      &:hover {
        background-color: #62f0e1;
        border-color: #62f0e1;
        color: #fff;
      }
      &:focus {
        background-color: #62f0e1;
        border-color: #62f0e1;
        color: #fff;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }
      &:active {
        background-color: #62f0e1;
        border-color: #62f0e1;
        color: #fff;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }
      &:before {
        content: "\f3fd";
        font-family: $ionicons;
        font-size: 30px;
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -15px;
      }
    }
  }
  .e-news-img {
    margin-bottom: 30px;
    max-height: 190px;
  }
  .e-read {
    border-radius: 3px;
    background-color: #53dccd;
    color: #fff;
    line-height: 1;
    padding: 5px 13px 5px 32px;
    font-size: 14px;
    font-family: $gravityBook-font;
    position: relative;
    float: right;
    vertical-align: top;
    &:before {
      content: "\f3fd";
      font-family: $ionicons;
      font-size: 28px;
      position: absolute;
      left: 13px;
      top: 50%;
      margin-top: -14px;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .e-partner {
    .e-news-box {
      height: auto;
    }
  }
}

@media only screen and (max-width: 520px) {
  .e-partner {
    .box.box-solid > .box-header .e-read-btn {
      margin-bottom: 30px;
    }
    .e-read {
      margin-bottom: 30px;
      display: inline-block;
    }
  }
}






