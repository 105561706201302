@import "_variables.scss";

.e-partner {
  .e-tickets-list {
    .btn.btn-success {
      background: 0 0;
      font-family: $gothamPro-reg-font;
      font-size: 14px;
      text-transform: uppercase;
      color: #54bece;
      border: 1px solid #62f0e1;
      border-radius: 15px;
      line-height: 1;
      padding: 11px 14px 9px 42px;
      display: inline-block;
      transition: all .1s;
      position: relative;
      margin-bottom: 20px;
      &:before {
        content: "\f48a";
        font-family: $ionicons;
        font-size: 18px;
        position: absolute;
        left: 14px;
        top: 50%;
        margin-top: -10px;
      }
      &:hover {
        background-color: #62f0e1;
        color: #fff;
      }
    }
    table {
       //min-width: 1024px;
      tr {
        th {
          &:first-child {
            min-width: 60px;
          }
        }
      }
      thead {
        tr {
          th {
            white-space: nowrap;
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
    .table-bordered>thead>tr>th:nth-child(n+2) {
      padding-left: 8px;
      padding-right: 40px;
    }
    .table-bordered>tbody>tr>td {
      padding-left: 8px;
      &:last-child {
        text-align: right;
      }
    }
  }
  .glyphicon-eye-open {
    &:before {
      content: '\e63d';
      font-family: $themify;
      font-size: 20px;
      color: #4298cc;
      transition: color .1s;
    }
    &:hover {
      &:before {
        color: #89c3e6;
      }
    }
  }
}