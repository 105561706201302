@import "_variables.scss";


//.tour-step-background {
//  background: transparent;
//  border: 2px solid blue;
//}
//.tour-backdrop {
//  opacity:0;
//}

//.tour-step-background,
//.tour-backdrop {
//  position: fixed;
//}

//.tour-step-background {
//  background: inherit !important;
//}

.e-partner {
  .alert.fade.in {
    border-radius: 10px;
    font-family: $gotham-light-font;
    font-size: 22px;
    padding: 32px 30px;
    margin-bottom: 30px;

    &.alert-success {
      background-color: #53dccd !important;
      border-color: #53dccd;
    }
    &.alert-danger {
      background-color: #f398b0 !important;
      border-color: #f398b0;
    }
    &.alert-warning {
      background-color: #f5ba5e !important;
      border-color: #f5ba5e;
    }
  }

  .alert.fade.in button.close {
    color: #fff;
    opacity: .7;
    margin-top: -26px;
    margin-right: -18px;
    font-weight: 100;
    font-size: 20px;
  }

  .alert.fade.in button.close:hover {
    opacity: 1;
  }
  .alert.fade.in {
    position: relative;
    padding-left: 88px;
    i {
      position: absolute;
      top: 50%;
      margin-top: -27px;
      left: 30px;
      &:before {
        content: "\f375";
        font-family: $ionicons;
        font-size: 48px;
      }
    }
    &.alert-danger {
      padding: 37px 30px 30px 92px;
      i {
        &:before {
          content: "\f05e";
          font-family: 'fontawesome';
        }
      }
    }
    &.alert-warning {
      padding: 35px 30px 30px 92px;
      i {
        &:before {
          content: "\f100";
          font-family: $ionicons;
        }
      }
    }
  }
  .alert-success {
    background-color: #53dccd !important;
    border-color: #53dccd;
  }
  .alert-danger {
    background-color: #f398b0 !important;
    border-color: #f398b0;
  }
  .alert-warning {
    background-color: #f5ba5e !important;
    border-color: #f5ba5e;
  }
  button.close {
    color: #fff;
    opacity: .7;
    font-weight: 100;
    font-size: 20px;
  }
}