@import "_variables.scss";

.e-partner {
  .e-add-website {
    background: none;
    font-family: $gothamPro-reg-font;
    font-size: 14px;
    text-transform: uppercase;
    color: #54bece;
    border: 1px solid #62f0e1;
    border-radius: 15px;
    line-height: 1;
    padding: 11px 14px 9px 40px;
    display: inline-block;
    transition: all .1s;
    position: relative;
    margin-bottom: 20px;
    &:hover {
      background-color: #62f0e1;
      color: #fff;
      &:before {
        border-color: #fff;
      }
    }
    &:focus {
      background-color: #62f0e1;
      color: #fff;
      border: 1px solid #62f0e1;
    }
    &:active {
      background-color: #62f0e1;
      color: #fff;
      border: 1px solid #62f0e1;
    }
    &:before {
      content: "\f48a";
      font-family: $ionicons;
      font-size: 18px;
      position: absolute;
      left: 14px;
      top: 50%;
      margin-top: -9px;
    }
  }
  .e-website-tour {
    background: none;
    font-family: $gothamPro-reg-font;
    font-size: 14px;
    text-transform: uppercase;
    color: #54bece;
    border: 1px solid #62f0e1;
    border-radius: 15px;
    line-height: 1;
    padding: 11px 14px 9px 12px;
    display: inline-block;
    transition: all .1s;
    position: relative;
    margin-bottom: 20px;
    &:hover {
      background-color: #62f0e1;
      color: #fff;
      &:before {
        border-color: #fff;
      }
    }
    &:focus {
      background-color: #62f0e1;
      color: #fff;
      border: 1px solid #62f0e1;
    }
    &:active {
      background-color: #62f0e1;
      color: #fff;
      border: 1px solid #62f0e1;
    }
  }
  .e-websites {
    .nav-tabs-custom {
      box-shadow: none;
      margin-bottom: 0;
      .e-nav-websites-refs {
        li {
          a {
            padding: 32px 26px 10px;
            font-family: $gotham-light-font;
            font-size: 18px;
            color: #555555;
            border: none;
            position: relative;
            &:after {
              content: '';
              display: block;
              width: 1px;
              height: 21px;
              background-image: linear-gradient(to top, rgba(197, 63, 79, .9) 0, rgba(142, 107, 141, .9) 52%, rgba(66, 152, 204, .9) 100%);
              position: absolute;
              right: 0;
              top: 30px;
            }
          }
          &.active {
            a {
              color: #000;
            }
          }
          &:last-child {
            a {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
    .box-body {
      padding-left: 0;
      padding-top: 0;
    }

  }
  .e-website-approve-btn {
    font-family: $gotham-light-font;
    font-size: 14px;
    text-transform: uppercase;
    color: #53dccd;
    background-color: transparent;
    border-color: #53dccd;
    border-radius: 19px;
    transition: all .1s;
    padding: 11px 21px;
    &:hover {
      background-color: #53dccd;
      color: #fff;
    }
    &:focus {
      border-color: #53dccd;
    }
    &:active {
      border-color: #53dccd;
    }
  }
  .e-website-menu-btn {
    font-family: $gotham-light-font;
    font-size: 14px;
    text-transform: uppercase;
    color: #53dccd;
    background-color: transparent;
    border-color: #53dccd;
    border-radius: 19px;
    transition: all .1s;
    padding: 11px 21px;
    &:hover {
      background-color: #53dccd;
      color: #fff;
    }
    &:focus {
      border-color: #53dccd;
    }
    &:active {
      border-color: #53dccd;
    }
  }
  .open {
    .e-website-menu-btn.dropdown-toggle {
      background-color: #53dccd;
      border-color: #53dccd;
      &:hover {
        background-color: #53dccd;
        border-color: #53dccd;
      }
    }
  }
  .table-bordered > tbody > tr > td a.e-website-approve-btn {
    font-family: $gotham-light-font;
    line-height: 1;
  }
  .table-bordered > tbody > tr > td a.e-website-menu-btn {
    font-family: $gotham-light-font;
    line-height: 1;
  }
  .table-bordered > tbody > tr > td a.e-website-copy-btn {
    font-family: $gotham-light-font;
    line-height: 1;
  }
  .e-website-copy-btn {
    font-family: $gotham-light-font;
    font-size: 14px;
    text-transform: uppercase;
    color: #d5beef;
    background-color: transparent;
    border-color: #d5beef;
    border-radius: 19px;
    transition: all .1s;
    padding: 11px 21px;
    margin-right: 10px;
    &:hover {
      background-color: #d5beef;
      color: #fff;
    }
    &:focus {
      border-color: #d5beef;
    }
    &:active {
      border-color: #d5beef;
    }
  }
  .e-code {
    background-color: transparent;
    border-color: #4298cc;
    border-radius: 19px;
    padding: 6px 21px;
    transition: all .1s;
    span {
      color: #4298cc;
    }
    &:hover {
      background-color: #4298cc;
      span {
        color: #fff;
      }
    }
    &:focus {
      background-color: #4298cc;
      border-color: #4298cc;
      span {
        color: #fff;
      }
    }
    &:active {
      background-color: #4298cc;
      border-color: #4298cc;
      span {
        color: #fff;
      }
    }
  }
  .e-code-menu {
    background-color: transparent;
    border-color: #4298cc;
    border-radius: 19px;
    padding: 6px 21px;
    transition: all .1s;
    span {
      color: #4298cc;
    }
    &:hover {
      background-color: #4298cc;
      span {
        color: #fff;
      }
    }
    &:focus {
      background-color: #4298cc;
      border-color: #4298cc;
      span {
        color: #fff;
      }
    }
    &:active {
      background-color: #4298cc;
      border-color: #4298cc;
      span {
        color: #fff;
      }
    }
  }
  .acceptance-websites-refs {
    table {
      tbody {
        tr {
          td {
            &:last-child {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1880px) {
  .e-partner .acceptance-websites-refs table tbody tr td:last-child {
    white-space: normal;
  }
  .e-partner .e-website-approve-btn {
    margin-right: 0;
    margin-bottom: 10px;
    display: block;
    max-width: 151px;
  }
  .e-partner .e-website-menu-btn {
    margin-right: 0;
    margin-bottom: 10px;
    display: block;
    max-width: 151px;
  }
  .e-partner .table-bordered > tbody > tr > td a.e-website-copy-btn {
    margin: 0;
  }
}

@media only screen and (max-width: 425px) {
  .e-partner .e-websites .nav-tabs-custom .e-nav-websites-refs li a {
    padding: 32px 15px 10px;
    font-size: 16px;
  }
}

