@import "_variables";

.e-partner {
  .e-form-head-wrap {
    margin-top: 10px;
    margin-bottom: 40px;
    padding-left: 17px;
  }
  .e-flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    label {
      font-family: $gotham-light-font;
      color: #54bece;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: normal;
      position: relative;
      cursor: pointer;
      border-radius: 19px;
      border: 1px solid #54bece;
      line-height: 1;
      padding: 13px 20px 11px 20px;
      transition: all .1s;
      &:hover {
        color: #fff;
        border-color: #54bece;
        background-color: #54bece;
      }
      &:focus {
        color: #fff;
        border-color: #54bece;
        background-color: #54bece;
        box-shadow: inset 2px 2px 6px rgba(0, 0, 0, .3);
      }
      &:active {
        color: #fff;
        border-color: #54bece;
        background-color: #54bece;
        box-shadow: inset 2px 2px 6px rgba(0, 0, 0, .3);
      }
      > input {
        visibility: hidden;
        position: absolute;
      }
    }
  }
  .e-flex-container.e-active-form {
    label {
      padding: 13px 20px 11px 44px;
      line-height: 1;
      &:before {
        content: "\f3fe";
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -11px;
        font-size: 22px;
        font-family: $ionicons;
      }
    }
  }
  .e-form-name {
    margin-bottom: 30px;
  }
  .e-form-head-text {
    padding: 10px 22px 0 0;
    margin-bottom: 44px;
    width: 70%;
    p {
      font-family: $gravityBook-font;
      color: #555;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .e-download-form-guide {
    p {
      display: inline-block;
      font-family: $gothamPro-reg-font;
      color: #555555;
      font-size: 14px;
      margin-right: 30px;
    }
    a {
      display: inline-block;
      font-family: $gotham-light-font;
      color: #6fa7c8;
      font-size: 14px;
      text-transform: uppercase;
      border-radius: 19px;
      border: 1px solid #6fa7c8;
      padding: 12px 18px 11px 43px;
      position: relative;
      line-height: 1;
      transition: all .1s;
      &:before {
        content: "\f407";
        font-family: $ionicons;
        font-size: 22px;
        position: absolute;
        left: 15px;
        top: 9px;
      }
      &:hover {
        color: #fff;
        border-color: #6fa7c8;
        background-color: #6fa7c8;
      }
      &:focus {
        color: #fff;
        border-color: #6fa7c8;
        background-color: #6fa7c8;
        box-shadow: inset 2px 2px 6px rgba(0, 0, 0, .3);
      }
      &:active {
        color: #fff;
        border-color: #6fa7c8;
        background-color: #6fa7c8;
        box-shadow: inset 2px 2px 6px rgba(0, 0, 0, .3);
      }
    }
  }
  #site_id {
    margin-left: 17px;
    max-width: 352px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAAXElEQVQoU2NcumpV2n8GBpeYsLAwBjxgyapVqxgZGPYwgtQsXbmy/D8jozEuTWDF//+fjQ4P7wRrwKcJWTFIHVwDNk3oijE0IGsCS0Kdgew1FBuQnQdig9yMHg4AN2s4ZmQtLrkAAAAASUVORK5CYII=') 94% center no-repeat;

  }
  .e-form-name {
    font-family: $gothamPro-reg-font;
    font-size: 22px;
    color: #555555;
    font-weight: 700;
  }
  .e-active-form {
    background-color: rgba(219, 249, 246, 0.6);
  }
  .e-add-padding {
    padding: 25px 32px;
  }
  .e-order-form-box {
    .box-body {
      padding: 14px 15px 34px 15px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .e-partner {
    .e-form-name {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .e-flex-container {
      label {
        font-size: 12px;
        &:before {
          top: 8px;
          font-size: 20px;
        }
      }
    }
    .e-form-head-text {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .e-partner {
    .e-flex-container {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-top: 36px;
      margin-bottom: 55px;
      &:last-child {
        margin-bottom: inherit;
      }
    }
    .e-form-name {
      margin-bottom: 10px;
    }
    .e-add-padding {
      &:last-child {
        padding-bottom: 0;
      }
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
@media (max-width: 767px) {
  .e-partner {
    .e-order-form-box .box-body {
      padding: 14px 15px 34px;
    }
    .e-form-head-wrap {
      padding-left: 0;
    }
    #site_id {
      margin-left: 0;
    }
    .e-add-padding {
      padding-left: 15px;
    }
  }
}