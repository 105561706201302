@import "_variables";

@media only screen and (max-width: 1650px) {
  .e-partner {
    .e-order-log-box {
      .grid-view {
        overflow-x: auto;
        visibility: hidden;
        &:hover {
          visibility: visible;
        }
        &:focus {
          visibility: visible;
        }
      }
    }
  }
}