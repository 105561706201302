@import "_variables.scss";

.e-partner {
  .main-footer {
    margin-left: 270px;
    border-top: none;
    background: #eff3f3;
    color: #8e8e8e;
    letter-spacing: 0.025em;

    strong {
      font-family: $gravityBook-font;
      font-weight: 100;
      color: #8e8e8e;
      letter-spacing: 0.025em;
      a {
        color: #8e8e8e;
      }
    }
  }
}


@media only screen and (max-width: 767px) {
  .e-partner {
    .main-footer {
      margin-left: 0;
    }
  }
}