@import "_variables";

.e-partner {
  .form-control {
    font-size: 15px;
    font-family: $gravityBook-font;
  }
  #e-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .e-request-payment-form {
    padding: 20px 0 30px;
    .e-icon-arrow.ti-angle {
      float: right;
      position: relative;
      top: -25px;
      right: 22px;
      color: #bec1c1;
      font-size: 12px;
    }
    .e-settings-btn {
      text-align: left;
    }
    select {
      border-radius: 5px;
      border-color: #e5e9e9;
      font-family: $gravityBook-font;
      -webkit-appearance: none;
      -moz-appearance:    none;
      appearance:         none;
    }
    .field-paymentrequestform-method {
      position: relative;
      &:after {
        content: "\e64b";
        font-family: $themify;
        display: block;
        position: absolute;
        right: 5%;
        top: 44px;
        font-size: 12px;
        color: #bec1c1;
      }
    }
    input {
      border-color: #e5e9e9;
    }
    label {
      font-size: 14px;
      margin-bottom: 14px;
      font-family: $gothamPro-reg-font;
      white-space: nowrap;
    }
    .e-settings-btn {
      margin-top: 16px;
      margin-bottom: 0;
      button {
        background-color: #3edccd;
        border: 1px solid transparent;
        border-radius: 20px;
        text-transform: uppercase;
        padding: 12px 29px;
        outline: 0;
        color: #fff;
        line-height: 1;
        transition: all .1s;
        &:hover {
          color: #3edccd;
          border: 1px solid #3edccd;
          background-color: transparent;
        }
        &:focus {
          color: #fff;
          border: 1px solid #3edccd;
          background-color: #3edccd;
          box-shadow: inset 2px 1px 1px rgba(0, 0, 0, .2);
        }
        &:active {
          color: #fff;
          border: 1px solid #3edccd;
          background-color: #3edccd;
          box-shadow: inset 2px 1px 1px rgba(0, 0, 0, .2);
        }
      }
    }
  }
  .e-border-right {
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: #e5e9e9;
      position: absolute;
      top: 8%;
      right: 0;
    }
  }
  .e-request-payment-important {
    padding-left: 50px;
    padding-top: 37px;
  }
  .e-important {
    color: #ca788a;
    font-size: 22px;
    font-family: $gotham-light-font;
    letter-spacing: 0.025em;
    margin-bottom: 20px;
    position: relative;
    padding-left: 34px;
    &:before {
      content: "\e6c5";
      display: block;
      font-family: $themify;
      position: absolute;
      top: -4px;
      left: 0;
    }
  }
  .e-request-payment-text {
    font-family: $gravityBook-font;
    color: #555555;
    max-width: 646px;
    line-height: 22px;
  }
  .e-balance {
    p {
      letter-spacing: 0.025em;
      font-size: 14px;
      font-family: $gothamPro-reg-font;
    }
    .e-balance-field {
      font-size: 30px;
      color: #54bece;
      margin-bottom: 22px;
      font-family: $gotham-light-font;
    }
  }
  #w3 {
    table {
      .btn-success {
        color: #54bece;
        background-color: transparent;
        border-color: #54bece;
        border-radius: 19px;
        transition: all .1s;
        padding: 8px 26px;
        &:hover {
          background-color: #54bece;
          border-color: #54bece;
          color: #fff;
        }
        &:focus {
          background-color: #54bece;
          border-color: #54bece;
          color: #fff;
        }
        &:active {
          background-color: #54bece;
          border-color: #54bece;
          color: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .e-partner {
    .e-request-payment-important {
      padding-left: 0;
    }
    .e-request-payment-form {
      padding-bottom: 0;
    }
    .e-border-right:after {
      display: none;
    }
  }
}