@import "_variables";

.e-partner {
  .e-profile {
    max-width: 1350px;
    .form-control {
      width: 100%;
    }
    .form-group {
      margin-bottom: 40px;
    }
    .nav {
      & > li {
        & > a {
          font-family: $gotham-light-font;
          font-size: 50px;
        }
      }
    }
  }
  .nav-tabs-custom {
    border-radius: 10px;
  }
  .tab-content {
    border-radius: 10px;
  }
  .e-settings-btn {
    max-width: 1350px;
    text-align: center;
    margin-bottom: 50px;
    button.btn-primary {
      background-color: #3edccd;
      border: 1px solid transparent;
      border-radius: 20px;
      text-transform: uppercase;
      padding: 9px 31px;
      letter-spacing: 0.025em;
      &:hover {
        background-color: transparent;
        color: #3edccd;
        border: 1px solid #3edccd;
      }
      &:focus {
        background-color: #3edccd;
        border: 1px solid #3edccd;
        color: #fff;
      }
      &:active {
        background-color: #3edccd;
        border: 1px solid #3edccd;
        color: #fff;
      }
    }
  }
  .nav-tabs-custom > .nav-tabs.e-nav-settings > li {
    > a {
      font-size: 18px;
      font-family: $gotham-light-font;
      color: #555555;
      line-height: 1;
      border: none;
      position: relative;
      padding: 32px 26px 10px;
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 21px;
        background-image: linear-gradient(to top, rgba(197, 63, 79, 0.9) 0%, rgba(142, 107, 141, 0.9) 52%, rgba(66, 152, 204, 0.9) 100%);
        position: absolute;
        right: 0;
        top: 30px;
      }
    }
    &:last-child {
      > a {
        &:after {
          display: none;
        }
      }
    }
  }
  .nav-tabs-custom > .nav-tabs > li.active {
    border: none;
  }
  .nav-tabs-custom > .nav-tabs > li {
    border: none;
  }
  .nav-tabs-custom > .nav-tabs {
    border: none;
  }
  .nav-tabs-custom > .nav-tabs > li.active a {
    position: relative;
    color: #000;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 6px;
      background-image: linear-gradient(to right, #d498a3 0%, #abc2db 100%);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .nav-tabs-custom > .tab-content {
    padding: 25px 26px 10px 26px;
  }
  .nav-tabs-custom > .e-nav-websites-refs + .tab-content {
    padding: 25px 8px 10px 26px;
  }
  .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #e5e9e9;
    border-radius: 5px;
  }
  .field-editprofileform-preferred {
    label {
      color: #555555;
      font-family: $gothamPro-reg-font;
      font-weight: 100;
    }
  }
  .field-editprofileform-preferred.has-success {
    label {
      color: #555555;
    }
  }

  .iti-flag {
    background-color: #e5e9e9;
  }
  .intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name {
    color: #555555;
    font-family: $gravityBook-font;
  }
  .intl-tel-input .country-list .country.highlight {
    background-color: #f3fbfb;
  }
  .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
    background-color: #f3fbfb;
  }
  .profile-preferred-communication {
    .form-group {
      display: inline-block;
      margin-right: 20px;
    }
    input {
      cursor: pointer;
      margin-right: 5px;
      &:checked + label {
        color: #4dbfcc;
      }
    }
    label {
      cursor: pointer;
      font-family: $gravityBook-font;
    }
  }

  .e-payment-padding {
    padding-top: 48px;
  }
  .change-payment.js-change-payment {
    border: 1px solid #d2d6de;
    border-radius: 5px;
    width: 200px;
    font-family: $gravityBook-font;
    font-size: 15px;
    color: #555555;
    margin-bottom: 14px;
    height: 34px;
    padding-left: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAAXElEQVQoU2NcumpV2n8GBpeYsLAwBjxgyapVqxgZGPYwgtQsXbmy/D8jozEuTWDF//+fjQ4P7wRrwKcJWTFIHVwDNk3oijE0IGsCS0Kdgew1FBuQnQdig9yMHg4AN2s4ZmQtLrkAAAAASUVORK5CYII=') 94% center no-repeat;
    background-size: 10px;
  }
  .e-settings-button {
    background-color: #3edccd;
    border: 1px solid transparent;
    border-radius: 20px;
    text-transform: uppercase;
    padding: 9px 31px;
    letter-spacing: .025em;
    outline: 0;
    transition: all .1s;
    margin-top: 20px;
    margin-bottom: 30px;
    &:hover {
      background: none;
      border: 1px solid #3edccd;
      color: #3edccd;
    }
    &:focus {
      background-color: #3edccd;
      border: 1px solid #3edccd;
      color: #fff;
    }
    &:active {
      background-color: #3edccd;
      border: 1px solid #3edccd;
      color: #fff;
    }
  }
  .e-payment-group-right {
    .btn.btn-primary {
      background-color: #3edccd;
      border: 1px solid transparent;
      border-radius: 20px;
      text-transform: uppercase;
      padding: 9px 31px;
      letter-spacing: .025em;
      transition: all .1s;
      margin-top: 10px;
      margin-bottom: 30px;
      &:hover {
        background: none;
        border: 1px solid #3edccd;
        color: #3edccd;
      }
      &:focus {
        background-color: #3edccd;
        border: 1px solid #3edccd;
        color: #fff;
      }
      &:active {
        background-color: #3edccd;
        border: 1px solid #3edccd;
        color: #fff;
      }
    }
    input[type="checkbox"] {
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
  }
  .e-table-notification {
    max-width: 70%;
    .width-15 {
      width: 39%;
    }
    .width-10 {
      width: 25%;
    }
    tr {
      th {
        font-family: $gothamPro-reg-font;
        font-size: 14px;
        color: #555555;
        font-weight: 400;
        padding-left: 0;
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 34px;
          top: 3px;
          background-color: #e5e9e9;
          width: 1px;
          height: 27px;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
      td {
        font-family: $gravityBook-font;
        padding-left: 0;
        vertical-align: middle;
        font-size: 14px;
        color: #555555;
        label {
          margin: 0;
        }
        .form-group {
          margin: 0;
        }
      }
      &:last-child {
        td {
          border-bottom: 1px solid #54bece;
        }
      }
    }
    & > thead > tr > th {
      border-color: #54bece;
    }
    & + .form-group {
      max-width: 70%;
      text-align: center;
    }
    tbody {
      tr {
        td {
          &:first-child {
            padding-right: 16px;
          }
        }
      }
    }
  }
  .e-notification-button {
    background-color: #3edccd;
    border: 1px solid transparent;
    border-radius: 20px;
    text-transform: uppercase;
    padding: 9px 31px;
    letter-spacing: .025em;
    outline: 0;
    transition: all .1s;
    margin-top: 20px;
    margin-bottom: 30px;
    &:hover {
      background: none;
      border: 1px solid #3edccd;
      color: #3edccd;
    }
    &:focus {
      background-color: #3edccd;
      border: 1px solid #3edccd;
      color: #fff;
    }
    &:active {
      background-color: #3edccd;
      border: 1px solid #3edccd;
      color: #fff;
    }
  }
  #local-settings-form {
    max-width: 50%;
    .form-control {
      border-radius: 5px;
    }
  }

  .field-localsettingsform-language {
    select {
      height: 36px;
    }
  }

  .e-local {
    max-width: 50%;
  }
  .e-local-button {
    background-color: #3edccd;
    border: 1px solid transparent;
    border-radius: 20px;
    text-transform: uppercase;
    padding: 9px 31px;
    letter-spacing: .025em;
    outline: 0;
    transition: all .1s;
    margin-top: 10px;
    margin-bottom: 30px;
    &:hover {
      background: none;
      border: 1px solid #3edccd;
      color: #3edccd;
    }
    &:focus {
      background-color: #3edccd;
      border: 1px solid #3edccd;
      color: #fff;
    }
    &:active {
      background-color: #3edccd;
      border: 1px solid #3edccd;
      color: #fff;
    }
  }
  .e-payment-group-right {
    label {
      font-weight: 100;
      font-size: 14px;
      color: #555;
      margin-bottom: 10px;
      font-family: $gothamPro-reg-font;
    }
  }
}

//Password:
.e-partner {
  .e-settings-btn.e-left-btn {
    text-align: left;
    margin-top: 30px;
  }
  .tab-password {
    .form-control {
      width: 50%;
    }
  }
}

//webmoney-form:
.e-partner {
  #webmoney-form {
    label {
      font-weight: 100;
      font-size: 14px;
      color: #555555;
      margin-bottom: 10px;
      font-family: $gothamPro-reg-font;
    }
  }
}

@media only screen and (max-width: 1365px) {
  .e-partner .e-table-notification + .form-group {
    max-width: 100%;
  }
  .e-partner .e-table-notification {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .e-partner .nav-tabs-custom > .nav-tabs.e-nav-settings > li > a {
    font-size: 16px;
    padding: 32px 14px 10px;
  }
}

@media only screen and (max-width: 1190px) {
  .e-partner .e-table-notification tr th:after {
    display: none;
  }
  .e-partner .e-table-notification .width-15 {
    width: 45%;
  }
  .e-partner {
    .tab-password {
      .form-control {
        width: 100%;
      }
    }
    #local-settings-form {
      max-width: 100%;
    }
    .e-local {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .e-partner {
    .e-nav-settings {
      li {
        display: block;
        float: none;
        margin-bottom: 18px;
      }
    }
    .nav-tabs-custom > .nav-tabs.e-nav-settings {
      padding-top: 20px;
      > li {
        text-align: center;
        > a {
          color: #4298cc;
          padding: 0;
          display: inline-block;
          &:before {
            display: none;
          }
          &:after {
            display: none;
          }
        }
        &.active {
          a {
            color: #000;
            &:after {
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background-image: linear-gradient(to right,#d498a3 0,#abc2db 100%);
              position: absolute;
              top: 18px;
            }
          }
        }
      }
    }
    .e-table-notification  {
      .checkbox {
        padding-left: 9px;
      }
    }
  }
  .e-partner .e-payment-padding {
    padding-top: 0;
  }
  .e-partner {
    .e-table-notification {
      tbody tr td:last-child {
        white-space: inherit;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .e-partner {
    .nav-tabs-custom > .tab-content {
      padding: 25px 3px 10px 15px;
    }
    .nav-tabs-custom>.tab-content.e-settings-content {
      padding: 25px 15px 10px;
    }
  }
  .e-partner .nav-tabs-custom>.e-nav-websites-refs+.tab-content {
    padding: 25px 8px 10px 15px;
  }
}

