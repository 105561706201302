@import "_variables.scss";

.e-partner {
  .ticket-create {
    padding-top: 20px;
    .form-control {
      height: 36px;
    }
    .field-ticket-file {
      label {
        background: none;
        box-shadow: none;
        background: 0 0;
        font-family: GothaProReg,sans-serif;
        font-size: 14px;
        text-transform: uppercase;
        color: #54bece;
        border: 1px solid #3edccd;
        border-radius: 20px;
        line-height: 1;
        padding: 13px 28px 10px 39px;
        transition: all .1s;
        position: relative;
        margin-bottom: 0;
        cursor: pointer;
        &:before {
          content: "\f367";
          font-family: $ionicons;
          position: absolute;
          left: 16px;
          top: 50%;
          font-size: 18px;
          margin-top: -9px;
        }
        &:hover {
          color: #fff;
          background-color: #3edccd;
        }
      }
    }
    input[type='file'] {
      display: none;
    }
    .form-group {
      margin-bottom: 25px;
    }
    .btn.btn-primary {
      background-color: #3edccd;
      border: 1px solid transparent;
      border-radius: 20px;
      transition: all .1s;
      text-transform: uppercase;
      padding: 12px 30px 10px;
      line-height: 1;
      &:hover {
        color: #3edccd;
        border-color: #3edccd;
        background-color: transparent;
      }
      &:focus {
        color: #fff;
        border-color: #3edccd;
        background-color: #3edccd;
      }
      &:active {
        color: #fff;
        border-color: #3edccd;
        background-color: #3edccd;
      }
    }
    .field-ticket-priority_id,
    .field-ticket-category_id {
      position: relative;
      &:after {
        content: "\e64b";
        font-family: $themify;
        display: block;
        position: absolute;
        right: 20px;
        top: 40px;
        font-size: 12px;
        color: #bec1c1;
      }
    }
    select {
      -webkit-appearance: none;
      -moz-appearance:    none;
      appearance:         none;
    }
    .form-group.field-ticket-file {
      margin-bottom: 5px;
    }
  }
  .js-file-placeholder {
    font-family: $gravityBook-font;
    color: #8e8e8e;
    font-size: 12px;
  }
}