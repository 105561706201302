//Fonts
@import '_variables';

@font-face {
  font-family: 'GothaProBol';
  src: url('../fonts/gotham/gothaprobol.eot');
  src: local('☺'), url('../fonts/gotham/gothaprobol.woff') format('woff'),
  url('../fonts/gotham/gothaprobol.ttf') format('truetype'),
  url('../fonts/gotham/gothaprobol.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamPro-Medium';
  src: url('../fonts/gotham/gothampro-medium.eot');
  src: url('../fonts/gotham/gothampro-medium.woff2') format('woff2'),
  url('../fonts/gotham/gothampro-medium.woff') format('woff'),
  url('../fonts/gotham/gothampro-medium.ttf') format('truetype'),
  url('../fonts/gotham/gothampro-medium.svg#gothampro-medium') format('svg'),
  url('../fonts/gotham/gothampro-medium.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothaProReg';
  src: url('../fonts/gotham/gothaproreg.eot');
  src: local('☺') , url('../fonts/gotham/gothaproreg.woff') format('woff'),
  url('../fonts/gotham/gothaproreg.ttf') format('truetype'), url('../fonts/gotham/gothaproreg.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'themify';
  src:url('../fonts/themify/themify.eot?-fvbane');
  src:url('../fonts/themify/themify.eot?#iefix-fvbane') format('embedded-opentype'),
  url('../fonts/themify/themify.woff?-fvbane') format('woff'),
  url('../fonts/themify/themify.ttf?-fvbane') format('truetype'),
  url('../fonts/themify/themify.svg?-fvbane#themify') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gravity-Book';
  src: url('../fonts/gravity/gravity-book.eot');
  src: local('O'), url('../fonts/gravity/gravity-book.woff') format('woff'),
  url('../fonts/gravity/gravity-book.ttf') format('truetype'),
  url('../fonts/gravity/gravity-book.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ionicons";
  src: url("../fonts/ionicons/ionicons.eot?v=2.0.0");
  src: url("../fonts/ionicons/ionicons.eot?v=2.0.0#iefix") format("embedded-opentype"),
  url("../fonts/ionicons/ionicons.ttf?v=2.0.0") format("truetype"),
  url("../fonts/ionicons/ionicons.woff?v=2.0.0") format("woff"),
  url("../fonts/ionicons/ionicons.svg?v=2.0.0#Ionicons") format("svg");
  font-weight: normal; font-style: normal;
}
@font-face {
  font-family: 'GothaProLig';
  src: url('../fonts/gotham/gothaprolig.eot');
  src: local('☺'), url('../fonts/gotham/gothaprolig.woff') format('woff'),
  url('../fonts/gotham/gothaprolig.ttf') format('truetype'),
  url('../fonts/gotham/gothaprolig.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Fontello';
  src: url('../fonts/fontello/fontello.eot');
  src: local('☺'), url('../fonts/fontello/fontello.woff') format('woff'),
  url('../fonts/fontello/fontello.ttf') format('truetype'),
  url('../fonts/fontello/fontello.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}