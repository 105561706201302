@import "_variables.scss";

.e-partner {
  .e-post {
    padding: 26px 0 10px;

    img {
      width: auto;
      margin-bottom: 24px;
      box-shadow: 0 0 17px -6px rgba(0, 0, 0, .69);
    }
    .lead {
      font-family: $gravityBook-font;
      color: #555555;
      font-size: 16px;
      font-style: italic;
      a {
        color: #4298cc;
      }
    }
  }
  .e-post-content {
    p, span, a {
      font-family: $gravityBook-font;
      color: #555555;
      font-size: 16px;
      line-height: 26px;
    }
    img {
      float: left;
      display: block;
      margin: 24px 24px 24px 0;
      width: 100%;
      height: auto;
    }
    a {
      text-decoration: underline;
    }
  }
  .e-post-data {
    font-size: 14px;
    color: #a4a9a9;
    display: inline-block;
    margin-bottom: 16px;
    font-family: $gotham-light-font;
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-image: linear-gradient(to right, #54bece 0, #5bd6d7 48%, #62f0e1 100%);
      opacity: .81;
      margin-top: 8px;
    }
  }
  .e-post-header {
    font-family: $gothamPro-reg-font;
    color: #555555;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    overflow: inherit;
    white-space: normal;
    max-width: inherit;
  }
}
@media (max-width: 1200px) {
  .e-partner {
    .e-post {
      .lead {
        margin-bottom: 34px;
      }
      .e-post-content {
        margin-top: 34px;
      }
    }
  }
}