@import "_variables";

.e-partner {
  .e-dashboard-chart {
    .morris-hover.morris-default-style {
      padding: 9px 20px;
      box-shadow: 0 5px 8px 1px rgba(0, 0, 0, 0.06), 0 1px 6px rgba(0, 0, 0, 0.03);
      border: 1px solid #e4e7e7;
      border-radius: 10px 8px 8px;
      background: #fff;
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 10px solid #fff;
        position: absolute;
        left: 50%;
        bottom: -10px;
        margin-left: -6px;
      }
    }
    .morris-hover-point {
      color: #555555 !important;
      font-size: 12px !important;
      font-family: $gravityBook-font !important;
    }
    .morris-hover-row-label {
      color: #8e8e8e !important;
      font-size: 10px !important;
      font-family: $gravityBook-font !important;
    }
  }
  path {
   fill-opacity: .5 !important;
  }
  .e-graph-axis-wrap {
    display: flex;
    align-items: center;
  }
  .e-graph-axis {
    color: #8e8e8e;
    font-size: 14px;
    font-family: $gravityBook-font;
  }
  .e-graph-axis-sites {
    margin-right: 30px;
    margin-left: 8px;
  }
  .e-circle-sites, .e-circle-referrals {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid #62f0e1;
  }
  .e-line-sites, .e-line-referrals {
    width: 15px;
    height: 2px;
    background-color: #62f0e1;
  }
  .e-graph-axis-referrals {
    margin-left: 8px;
  }
  .e-circle-referrals {
    border-color: #f398b0;
  }
  .e-line-referrals {
    background-color: #f398b0;
  }
}

@media (max-width: 480px) {
  .e-partner {
    .e-graph-axis-sites {
      margin-right: 10px;
    }
    .e-graph-axis {
      font-size: 11px;
    }
    .e-line-sites,
    .e-line-referrals {
      width: 6px;
    }
    .e-graph-axis-wrap {
      flex-wrap: wrap;
      margin-top: 4px;
    }
    .e-y-sign {
      font-size: 11px;
    }
  }
}