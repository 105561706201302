@import "_variables";

.e-partner {

  .collapsing{
    -webkit-transition: height 0;
    -moz-transition: height 0;
    -ms-transition: height 0;
    -o-transition: height 0;
    transition: height 0;
  }

  .traffic-source {
    .child-row {
      background: #f1f1f1 !important;
      font-size: 13px;
      .source {
        padding-left: 20px;
      }
    }
  }

  #form-analytics {
    .form-control.js-pjax-init {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      padding: 6px 30px 6px 10px;
      box-sizing: border-box;
      border: 1px solid #e4e7e7;
      border-radius: 5px;
      height: 40px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
    .form-control.js-pjax-init::-ms-expand {
      display: none;
    }
    .input-group {
      box-shadow: none;
      border-bottom: 1px solid #e4e7e7;
      border-radius: 5px;
    }
    .input-group-addon.js-date-calendar {
      border-bottom-left-radius: 5px;
    }
    .input-group-addon.js-date-remove {
      border-bottom-right-radius: 5px;
    }
    .e-icon-arrow.ti-angle {
      float: right;
      position: relative;
      top: -27px;
      right: 22px;
      color: #bec1c1;
      font-size: 12px;
    }
  }

  //boxes:
  .box.small-box {
    background-color: #fff !important;
    border-radius: 12px 0 0 12px;
    &.bg-red {
      border-radius: inherit;
      h3 {
        color: #53dccd;
      }
    }
    &.bg-yellow {
      border-radius: inherit;
      .icon {
        top: 64px;
      }
      h3 {
        color: #d5beef;
      }
    }
    &.bg-green {
      .icon {
        top: 50px;
        right: 24px;
      }
      border-radius: 0 12px 12px 0;
      h3 {
        color: #6fa7c8;
      }
    }
    .icon {
      top: 58px;
      right: 30px;
    }
  }
  .small-box > .inner {
    padding: 56px 15px 52px 26px;
  }
  .fa-users.ion-person-stalker {
    &:before {
      content: '\f212';
      color: #f398b6;
      opacity: 0.18;
      font-size: 8vw;
    }
  }
  .ion-pie-graph.ion-social-reddit {
    &:before {
      content: '\f23b';
      color: #53dccd;
      opacity: 0.18;
      font-size: 8vw;
    }
  }
  .fa-eye.ion-ios-eye {
    &:before {
      content: '\f425';
      color: #d5beef;
      opacity: 0.18;
      font-size: 8vw;
    }
  }
  .ion-stats-bars.ion-android-walk {
    &:before {
      content: '\f3bb';
      color: #6fa7c8;
      opacity: 0.18;
      font-size: 8vw;
    }
  }
  .small-box h3 {
    color: #f398b6;
    font-family: $gotham-light-font;
    font-weight: 100;
    font-size: 3.5vw;
    margin: 0;
  }
  .small-box p {
    color: #555555;
    font-family: $gotham-light-font;
    font-weight: 100;
    font-size: 1.1vw;
  }
  .e-boxes-pd_null {
    div {
      box-shadow: none;
    }
    > div {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 30px;
        right: 0;
        width: 1px;
        height: 70%;
        background-image: linear-gradient(to top, rgba(197, 63, 79, 0.9) 0%, rgba(142, 107, 141, 0.9) 52%, rgba(66, 152, 204, 0.9) 100%);
        opacity: .54;
      }

      &:first-child {
        padding-right: 0;
      }
      &:nth-child(2) {
        padding-left: 0;
        padding-right: 0;
      }
      &:nth-child(3) {
        padding-right: 0;
        padding-left: 0;
      }
      &:last-child {
        padding-left: 0;
        &:after {
          display: none;
        }
      }
    }
  }
  //end boxes;
}

//Line-chart:
.e-partner {
  .e-analytics-chart {
    path {
      fill-opacity: .5 !important;
    }
    .morris-hover-point {
      color: #555555 !important;
      font-size: 12px !important;
      font-family: $gravityBook-font !important;
    }
    .morris-hover-row-label {
      color: #8e8e8e !important;
      font-size: 10px !important;
      font-family: $gravityBook-font !important;
    }
    .morris-hover.morris-default-style {
      padding: 9px 20px;
      box-shadow: 0 5px 8px 1px rgba(0, 0, 0, 0.06), 0 1px 6px rgba(0, 0, 0, 0.03);
      border: 1px solid #e4e7e7;
      border-radius: 10px 8px 8px;
      background: #fff;
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 10px solid #fff;
        position: absolute;
        left: 50%;
        bottom: -10px;
        margin-left: -6px;
      }
    }
  }
}

//Line-chart;

.e-partner {
  .jqvmap-label {
    background-color: #fff;
    box-shadow: 0 5px 8px 1px rgba(0, 0, 0, 0.06), 0 1px 6px rgba(0, 0, 0, 0.03);
    border-radius: 10px 8px 8px;
    border: 1px solid #e4e7e7;
    color: #8e8e8e;
    font-family: $gravityBook-font;
    font-size: 12px;
    text-align: center;
    padding: 10px 15px;
    b {
      color: #555555;
      font-family: $gravityBook-font;
    }
  }
  //map:
  .col-map {
    text-transform: capitalize;
    min-height: 488px;
    .box-footer {
      border-top: none;
      border-radius: 12px;
    }
    .box {
      border-radius: 12px;
    }
    .box-header.js-box-header {
      padding: 30px 22px 10px 21px;
    }
    .box-body {
      padding: 14px 22px 62px 21px;
    }
    .box-header {
      .box-title {
        font-size: 18px;
      }
    }
  }

  //col-engines:
  .col-engines {
    min-height: 488px;
    .box-body {
      padding: 14px 22px 38px 21px;
    }
    .box-footer {
      border-top: none;
      border-radius: 12px;
    }
    .box {
      border-radius: 12px;
    }
    .box-header {
      padding: 30px 22px 10px 21px;
      .box-title {
        font-size: 18px;
      }
    }
    text {
      fill: #555555;
    }
    #engine-chart {
      svg {
        text {
          font-family: $gotham-light-font !important;
          font-size: 5px !important;
          font-weight: 100 !important;
        }
      }
    }
    .chart-legend {
      display: flex;
      justify-content: space-between;
      max-width: 564px;
      margin: 0 auto;
      span {
        font-family: $gravityBook-font;
        color: #8e8e8e;
        span {
          width: 14px;
          height: 14px;
          border: 3px solid #6fa7c8;
          border-radius: 50%;
          background-color: transparent !important;
        }
        &:nth-child(2) {
          span {
            border-color: #ce788b;
          }
        }
        &:nth-child(3) {
          span {
            border-color: #4ff0e1;
          }
        }
        &:nth-child(4) {
          span {
            border-color: #d7beed;
          }
        }
        &:nth-child(5) {
          span {
            border-color: #47becd;
          }
        }
        &:nth-child(6) {
          span {
            border-color: #f8abbe;
          }
        }
      }
      .chart-label {
        display: flex;
        align-items: center;
        margin-right: 2px;
      }
    }
  }

  //e-top-visit:
  .e-top-visit {
    .box {
      border-radius: 12px;
    }
    .box-body {
      padding: 14px 22px 36px 21px;
    }
    .box-header {
      padding: 30px 22px 10px 21px;
      .box-title {
        font-size: 18px;
      }
    }
    table {
      tbody {
        tr {
          &:first-child {
            td {
              border-top: none;
            }
          }
          td {
            padding: 10px 10px 10px 0;
            font-family: $gravityBook-font;
            font-size: 14px;
            color: #555555;
            letter-spacing: 0.025em;
          }
        }
      }
    }
    .box-footer {
      display: none;
    }
  }
}

@media only screen and (max-width: 1365px) {
  .e-partner {
    .col-engines {
      .chart-legend {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      .box-body {
        padding: 14px 22px 14px 21px;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .e-partner {
    #form-analytics {
      #filter-form {
        margin-bottom: 16px;
      }
    }
    .small-box {
      h3 {
        font-size: 74px;
      }
      p {
        font-size: 20px;
      }
    }
    .e-boxes-pd_null > div:nth-child(3) {
      padding-left: 15px;
      border-radius: 12px 0 0 12px;
    }
    .e-boxes-pd_null > div:nth-child(2) {
      padding-right: 15px;
      border-radius: 0 12px 12px 0;
    }
    .fa-users.ion-person-stalker:before {
      font-size: 134px;
    }
    .ion-stats-bars.ion-android-walk:before {
      font-size: 134px;
    }
    .ion-pie-graph.ion-social-reddit:before {
      font-size: 134px;
    }
    .fa-eye.ion-ios-eye:before {
      font-size: 134px;
    }
    .box.small-box.bg-green .icon {
      right: 35px;
    }
    .e-boxes-pd_null > div:nth-child(2):after {
      display: none;
    }
  }
}

@media only screen and (max-width: 991px) {
  .e-partner {
    .small-box {
      > .inner {
        padding: 36px 15px 52px 26px;
      }
      h3 {
        font-size: 52px;
      }
      p {
        font-size: 18px;
      }
    }
    .box.small-box .icon {
      right: 8px;
      top: 72px;
    }
    .fa-eye.ion-ios-eye:before,
    .fa-users.ion-person-stalker:before,
    .ion-pie-graph.ion-social-reddit:before,
    .ion-stats-bars.ion-android-walk:before {
      font-size: 100px;
    }
    .box.small-box.bg-yellow .icon {
      top: 72px;
    }
    .box.small-box.bg-green .icon {
      top: 60px;
      right: 12px;
    }
    .col-map, .col-engines {
      min-height: inherit;
    }
    .e-boxes-pd_null {
      .small-box {
        min-height: 205px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .e-partner {
    .small-box > .inner {
      padding: 42px 15px 36px 18px;
    }
    .small-box {
      margin-bottom: 0;
      h3 {
        font-size: 70px;
      }
      p {
        font-size: 20px;
      }
      &:after {
        content: '';
        display: block;
        top: 0;
        position: relative;
        height: 1px;
        width: 90%;
        margin: 0 auto;
        background-image: linear-gradient(to right, rgba(197, 63, 79, 0.9) 0%, rgba(142, 107, 141, 0.9) 52%, rgba(66, 152, 204, 0.9) 100%);
        opacity: .54;
      }
    }
    .box.small-box .icon {
      top: 10px;
      display: block;
      right: 6%;
      i {
        &:before {
          font-size: 150px;
        }
      }
    }
    .e-top-visit {
      table {
        tbody {
          tr {
            td:nth-child(2) {
              max-width: 60%;
              word-break: break-all;
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }
  .e-partner .e-boxes-pd_null > div:first-child {
    padding-right: 15px;
    border-radius: 12px;
  }
  .e-partner .e-boxes-pd_null > div:nth-child(2), .e-partner .e-boxes-pd_null > div:nth-child(3) {
    padding-right: 15px;
    padding-left: 15px;
  }
  .e-partner .e-boxes-pd_null > div:last-child {
    padding-left: 15px;
  }
  .e-partner .box.small-box {
    border-radius: 12px 12px 0 0;
  }
  .e-partner .e-boxes-pd_null > div:nth-child(2) {
    border-radius: 0;
  }
  .e-partner .box.small-box.bg-yellow {
    border-radius: 0;
  }
  .e-partner .box.small-box.bg-green {
    border-radius: 0 0 12px 12px;
    margin-bottom: 24px;
    &:after {
      display: none;
    }
  }
  .e-partner .box.small-box.bg-yellow .icon {
    top: 11px;
  }
  .e-partner .box.small-box.bg-green .icon {
    top: 8px;
    right: 6%;
  }
  .e-partner .e-boxes-pd_null > div:after {
    display: none;
  }
}

@media (max-width: 480px) {
  .e-partner {
    .small-box {
      text-align: left;
    }
  }
}


