@import "_variables.scss";

.e-partner {
  .wrapper {
    height: 100%;
    min-width: 320px;
  }
  .content-wrapper {
    min-height: 920px !important;
  }
  h1 {
    font-family: $gotham-light-font;
    color: #555555;
    font-size: 22px;
    font-weight: 400;
    max-width: 420px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: inherit;
  }
  .has-error {
    .help-block {
      color: #f398b0;
      font-family: $gravityBook-font;
    }
  }
  .row {
    visibility: visible;
  }

  //Label success - error:
  .form-group.has-error label {
    color: #f398b0;
  }
  .form-group.has-error .form-control, .form-group.has-error .input-group-addon {
    border-color: #f398b0;
  }
  .form-group.has-error .help-block {
    color: #f398b0;
    font-family: $gravityBook-font;
  }
  .form-group.has-success .form-control, .form-group.has-success .input-group-addon {
    border-color: #4dbfcc;
  }
  .form-group.has-success label {
    color: #4dbfcc;
  }
  .not-set {
    color: #f398b0;
    font-style: italic;
    font-family: $gotham-light-font;
  }
  //Label success - error;

  //Inputs:
  input {
    border-radius: 5px;
    &:focus {
      border-color: #d2d6de;
    }
  }
  //Inputs;

  //Labels:
  label.control-label {
    font-weight: 100;
    font-size: 14px;
    color: #555555;
    margin-bottom: 10px;
    font-family: $gothamPro-reg-font;
  }
  label.label-danger {
    font-family: $gravityBook-font;
    font-size: 14px;
    font-weight: 100;
    border-radius: 3px;
    background-color: #ca788a !important;
    padding: 3px 10px;
  }
  label.label-success {
    font-family: $gravityBook-font;
    font-size: 14px;
    font-weight: 100;
    border-radius: 3px;
    background-color: #53dccd !important;
    padding: 3px 10px;
  }
  label.label-warning {
    font-family: $gravityBook-font;
    font-size: 14px;
    font-weight: 100;
    border-radius: 3px;
    background-color: #f5ba5e !important;
    padding: 3px 10px;
  }
  label.label-default {
    font-family: $gravityBook-font;
    font-size: 14px;
    font-weight: 100;
    border-radius: 3px;
    background-color: #6fa7c8 !important;
    padding: 3px 27px;
    color: #fff;
  }
  //Labels;

  //Table Buttons:
  table {
    overflow-x: auto;
    .btn-danger {
      color: #f398b0;
      background-color: transparent;
      border-color: #f398b0;
      border-radius: 19px;
      transition: all .1s;
      padding: 8px 26px;
      &:hover {
        background-color: #f398b0;
        border-color: #f398b0;
        color: #fff;
      }
      &:focus {
        background-color: #f398b0;
        border-color: #f398b0;
        color: #fff;
      }
      &:active {
        background-color: #f398b0;
        border-color: #f398b0;
        color: #fff;
      }
    }
  }
  //Table Buttons;

  .input-group {
    .input-group-addon {
      border-color: #e4e7e7;
    }
  }

  input, textarea, select {
    border-color: #e4e7e7;
    border-radius: 5px;
    &:focus {
      border-color: #e4e7e7;
    }
  }
  .summary {
    visibility: visible;
  }
  .empty {
    text-align: left;
  }
  .e-th-middle {
    top: 55px;
    padding-right: 16px;
  }
  .e-th-last-child {
    top: 44px;
  }
  input[type='checkbox'] {
    cursor: pointer;
  }
  .control-sidebar-dark {
    display: none;
  }

  //Datatables style:
  table.dataTable {
    border-collapse: collapse;
  }
}

@media only screen and (max-width: 1365px) {
  .e-partner {
    .grid-view {
      overflow-x: auto;
      visibility: hidden;
      &:hover {
        visibility: visible;
      }
      &:focus {
        visibility: visible;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .e-partner {
    h1 {
      max-width: inherit;
      white-space: inherit;
    }
    .grid-view {
      overflow-x: auto;
      visibility: visible;
    }
  }
}
@media (min-width: 768px) and (max-width: 788px) {
  .e-partner {
    .box-body {
      padding: 14px 22px 100px 9px;
    }
    .box-header {
      padding: 40px 22px 10px 9px;
    }
  }
}