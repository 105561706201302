@import "_variables.scss";

.e-partner {

  [class^="ti-"], [class*=" ti-"], [class*="fa-ti-"] {
    font-family: $themify;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  [class^="fo-"]:before, [class*=" fo-"]:before {
    font-family: $fontello;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    color: #6fa7c8;
    font-size: 15px;
  }

  #filter-form {
    .input-group {
      max-width: 360px;
      position: relative;
      height: 40px;
      max-height: 40px;
      box-shadow: 0 1px 9px rgba(0, 0, 0, 0.1);
      z-index: 1100;
      &:after {
        content: '';
        display: block;
        height: 20px;
        width: 1px;
        background-color: #d6dada;
        position: absolute;
        left: 50%;
        top: 10px;
        z-index: 100;
      }
    }
  }
  .e-short {
    .form-control {
      width: 50%;
      border-color: #e4e7e7;
      font-size: 16px;
      color: #555555;
      background-color: #fff !important;
    }
  }

  .glyphicon-calendar.fo-calendar:before  {
    content: '\e801';
    position: relative;
    top: -2px;
  }

  .input-group-addon.js-date-calendar {
    border-top-left-radius: 4px;
    border-right: none;
    border-bottom: none;
  }
  .form-control.js-date-from,
  .form-control.js-date-to {
    border-left: none ;
    border-right: none;
    border-bottom: none;
    background-color: #fff;
    outline: none;
    height: 40px;

    &:focus {
      border: 1px solid #e4e7e7;
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
  }
  .daterangepicker:before {
    display: none;
  }
  .daterangepicker.opensright:after {
    display: none;
  }
  .input-group-addon.js-date-remove {
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom: none;
  }
  .glyphicon-remove:before {
    content: "\e646" !important;
    position: relative;
    font-family: $themify;
    color: #bec1c1;
    font-size: 11px;
    top: -2px;
  }
  .daterangepicker.dropdown-menu.ltr.opensright.show-calendar {
    left: 286px !important;
    box-shadow: 1px 5px 7px rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 16px 8px 25px 8px;
    width: auto;
  }
  .input-mini,
  .fa.fa-calendar.glyphicon.glyphicon-calendar {
    display: none;
  }
  .ranges li {
    background-color: #e5e9e9;
    border: none;
    font-size: 14px;
    color: #555555;
    border-radius: 16px 15px 15px;
    padding: 6px 11px;
    text-align: center;
    margin-bottom: 5px;
    &:hover {
      color: #fff;
      border: none;
      background-color: #54bece;
    }
  }
  .fa.glyphicon-chevron-left {
    font-family: $themify;
    color: #ccc;
    &:before {
      content: '\e64a';
      color: #8e8e8e;
      font-size: 10px;
    }
  }
  .fa.glyphicon-chevron-right {
    font-family: $themify;
    color: #ccc;
    &:before {
      content: "\e649";
      color: #8e8e8e;
      font-size: 10px;
    }
  }
  .monthselect,
  .yearselect {
    background: none;
    color: #555555;
    font-size: 14px;
    border: none;
    width: 54px !important;

  }
  .monthselect,
  .yearselect {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAAXElEQVQoU2NcumpV2n8GBpeYsLAwBjxgyapVqxgZGPYwgtQsXbmy/D8jozEuTWDF//+fjQ4P7wRrwKcJWTFIHVwDNk3oijE0IGsCS0Kdgew1FBuQnQdig9yMHg4AN2s4ZmQtLrkAAAAASUVORK5CYII=')  no-repeat
    90% center;
    background-size: 10px;
  }
  .monthselect {
    background-position: 78% center;
  }
  .daterangepicker select.monthselect, .daterangepicker select.yearselect {
    font-size: 13px;
    color: #555555;
    font-family:$gothamPro-reg-font;
    font-weight: 100;
  }
  .prev.available,
  .next.available {
    padding: 0;
    &:hover {
      background-color: #54bece;
      .fa.glyphicon-chevron-left:before,
      .fa.glyphicon-chevron-right:before {
        color: #fff;
      }
    }
  }
  .daterangepicker .calendar {
    padding: 0 4px 4px 10px;
    margin: 0 4px 4px;
    thead {
      tr:last-child {
        th {
          border-top: 1px solid #e4e7e7;
          border-bottom: 1px solid #e4e7e7;
          &:first-child,
          &:last-child {
            color: #e0483e;
          }
        }
      }
    }
    tbody {
      td {
        font-size: 13px;
      }
    }
  }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #54bece;
  }
  .daterangepicker td.active, .daterangepicker td:hover {
    background-color: #54bece;
    color: #fff;
    border-radius: 4px;
  }
  .active.start-date.active.end-date.in-range.available {
    color: #fff;
    background-color: #54bece;
    &:hover {
      color: #fff;
    }
  }
  .in-range.available {
    background: none;
    color: #54bece;
  }
  .daterangepicker td.start-date,
  .daterangepicker td.end-date {
    border-radius: 4px;
  }
  .daterangepicker .calendar th {
    color: #555555;
    font-weight: 100;
  }
  .daterangepicker .calendar td {
    max-width: 28px;
    min-width: inherit;
  }
  .off.disabled:hover {
    background-color: inherit;
    color: #a4a9a9;
  }
  .ranges {
    margin: 7px 4px 4px 10px;
  }
  .input-group-addon.js-date-calendar {
    padding: 6px 12px 6px 18px;
  }
  .table-condensed > tbody > tr > td {
    padding: 4px;
  }

  .applyBtn.btn.btn-success,
  .cancelBtn.btn.btn-default {
    line-height: 1;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #53dccd;
    border-radius: 19px;
    padding: 9px 28px 8px;
    color: #fff;
    outline: none;
    border: 1px solid transparent;
    transition: all .1s;
    position: relative;
    &:hover {
      background: none;
      border: 1px solid #53dccd;
      color: #53dccd;
    }
    &:focus {
      background-color: #53dccd;
      border: 1px solid #53dccd;
      color: #fff;
    }
    &:active {
      background-color: #53dccd;
      border: 1px solid #53dccd;
      color: #fff;
    }
  }
  .cancelBtn.btn.btn-default {
    line-height: 1;
    background: none;
    color: #f398b0;
    border: 1px solid #f398b0;
    position: absolute;
    left: 465px;
    padding: 9px 22px 8px;
    &:hover {
      background: #f398b0;
      border: 1px solid #f398b0;
      color: #fff;
    }
    &:focus {
      background: #f398b0;
      border: 1px solid #f398b0;
      color: #fff;
    }
    &:active {
      background: #f398b0;
      border: 1px solid #f398b0;
      color: #fff;
    }
  }
  .range_inputs {
    margin-top: 20px;
    position: relative;
    white-space: nowrap;
  }
  .calendar.left {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      height: 90%;
      top: 12px;
      width: 1px;
      background-color: #e4e7e7;
    }
  }
  .daterangepicker .calendar tbody td {
    padding: 4px 0;
    max-width: 28px;
    min-width: 28px;
  }
  .table-condensed > thead > tr > th {
    padding: 5px 0;
  }
  .daterangepicker .calendar th, .daterangepicker .calendar td {
    min-width: 28px;
  }
  &.sidebar-collapse {
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar {
      left: 65px !important;
    }
  }
  .daterangepicker.dropdown-menu.ltr.opensright {
    width: 360px;
    .cancelBtn.btn.btn-default {
      left: 190px;
    }
    .ranges {
      margin: 17px 26px 4px 26px;
      width: auto;
      float: none;
      ul {
        width: 100%;
      }
    }

    &.show-calendar {
      .ranges {
        margin: 7px 4px 4px 10px;
        float: left;

        ul {
          width: 148px;
        }
      }
      .cancelBtn.btn.btn-default {
        left: 470px;
      }
    }
  }
}


.daterangepicker th {
  border: inherit;
}



@media (min-width: 949px) {
  .e-partner {
    .daterangepicker {
      width: auto;
    }
  }
}


@media only screen and (max-width: 767px) {
  .e-partner {
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar {
      left: 16px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 948px) {
  .e-partner {
    .calendar.left:after {
      display: none;
    }
    .daterangepicker .calendar {
      margin: 0 24px 9px;
    }
    .daterangepicker.ltr .calendar.right {
      margin-left: 15px;
    }
    .ranges {
      margin: 11px 4px 4px 10px;
    }
    .daterangepicker .calendar {
      margin: 0 4px 4px 10px;
    }
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar {
      width: 452px;
    }
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar .cancelBtn.btn.btn-default {
      left: 26px;
      top: 56px;
    }
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar .cancelBtn.btn.btn-success {
      left: 26px;
    }
    .applyBtn.btn.btn-success {
      left: 26px;
    }
  }

}

@media (max-width: 732px) {
  .e-partner {
    .daterangepicker.ltr .calendar.left {
      clear: none;
    }
    .calendar.left:after {
      display: none;
    }
    .daterangepicker.ltr .calendar.right {
      margin-left: 8px;
    }
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar {
      width: 420px;
    }
    .applyBtn.btn.btn-success {
      left: 26px;
    }
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar .cancelBtn.btn.btn-default {
      left: 26px;
      top: 56px;
    }
  }
}

@media (max-width: 560px) {
  .e-partner {
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar .ranges {
      float: none;
      margin: 7px 4px;
    }
    #filter-form .input-group {
      max-width: 290px;
    }
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar {
      max-width: 289px;
    }
    .daterangepicker.ltr .calendar.left,
    .daterangepicker.ltr .calendar.right {
      max-width: inherit;
      margin-top: 20px;
    }
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar .cancelBtn.btn.btn-default {
      top: 0;
      left: 157px;
    }
    .range_inputs {
      margin-top: 19px;
    }
    .daterangepicker.dropdown-menu.ltr.opensright {
      max-width: 322px;
    }
    .daterangepicker.dropdown-menu.ltr.opensright {
      .applyBtn.btn.btn-success {
        left: 0;
      }
    }
    .daterangepicker.dropdown-menu.ltr.opensright .cancelBtn.btn.btn-default {
      left: 168px;
    }
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar {
      .applyBtn.btn.btn-success {
        left: 1px;
      }
    }
    .daterangepicker.dropdown-menu.ltr.opensright.show-calendar .ranges ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      li {
        width: 47%;
        font-size: 13px;
        margin-bottom: 10px;
        padding: 8px 11px 6px;
      }
    }
    .e-short .form-control {
      font-size: 13px;
    }
    .daterangepicker .calendar {
      padding: 0 0 4px 0;
    }
    .daterangepicker .calendar tbody td {
      padding: 8px 0;
    }
  }
}

