@import "_variables";

.e-partner {
  .e-refs-id-btn {
    background: 0 0;
    font-family: GothaProReg, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #54bece;
    border: 1px solid #62f0e1;
    border-radius: 15px;
    line-height: 1;
    padding: 11px 14px 9px 40px;
    display: inline-block;
    transition: all .1s;
    position: relative;
    margin-bottom: 20px;
    &:hover {
      background-color: #62f0e1;
      color: #fff;
    }
    &:focus {
      background-color: #62f0e1;
      color: #fff;
      border: 1px solid #62f0e1;
    }
    &:active {
      background-color: #62f0e1;
      color: #fff;
      border: 1px solid #62f0e1;
    }
    &:before {
      content: "\f48a";
      font-family: $ionicons;
      font-size: 18px;
      position: absolute;
      left: 14px;
      top: 50%;
      margin-top: -9px;
    }
  }
  .e-refs-copy-btn {
    display: inline-block;
    width: auto;
    color: #fff;
    background-color: #4298cc;
    border-color: #4298cc;
    border-radius: 19px;
    line-height: 1;
    padding: 10px 15px;
    font-size: 14px;
    font-family: $gotham-light-font;
    text-transform: uppercase;
    transition: all .1s;
    &:hover {
      background-color: transparent;
      color: #4298cc;
    }
    &:focus,
    &:active {
      background-color: #4298cc;
      color: #fff;
    }
  }

  .e-refs-copy {
    display: inline-block;
    margin-bottom: 1vh;
    width: auto;
    color: #4298cc;
    border: none;
    background-color: #fff;
    font-size: 14px;
    font-family: $gotham-light-font;
    &:hover {
      color: #4298cc;
    }
    &:focus,
    &:active {
      color: #4298cc;
    }
  }

  .e-refs-delete-a{
    display: inline-block;
    width: auto;
    color: #cc0501;
    border: none;
    background-color: #fff;
    font-size: 14px;
    font-family: $gotham-light-font;
    &:hover {
      background-color: transparent;
      color: #cc7569;
    }
    &:focus,
    &:active {
      color: #cc7569;
    }
  }
}